import Image from 'next/legacy/image';
import { ReactNode } from 'react';
import classes from './Gradient.module.css';

type Props = {
  children: ReactNode;
  backgroundImage: string;
  gradientClass?: string;
  hasImagePriority?: boolean;
  imageTitle?: string;
};

function Gradient(props: Props) {
  const { gradientClass, backgroundImage, hasImagePriority, imageTitle } = props;

  return (
    <div
      className={
        gradientClass && classes[`${gradientClass}Gradient`]
          ? `${classes.container} ${classes[`${gradientClass}Gradient`]}`
          : classes.container
      }
    >
      {backgroundImage && (
        <Image
          alt={imageTitle ?? ''}
          src={backgroundImage}
          layout="fill"
          objectFit="cover"
          priority={hasImagePriority === true ? true : false}
        />
      )}
      <div className={classes.gradient}></div>
      <div className={classes.internalContainer}>{props.children}</div>
    </div>
  );
}

export default Gradient;
