import FacebookIcon from '@/components/icons/FacebookIcon';
import InstagramIcon from '@/components/icons/InstagramIcon';
import TwitterIcon from '@/components/icons/TwitterIcon';
import AnakinworldLineLogo from '@/components/icons/logos/AnakinworldLineLogo';
import { MenuCategory } from '@/hooks/useMenu';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import Link from 'next/link';
import classes from './MobileMenu.module.css';

type Props = {
  items: Array<MenuCategory>;
};

function MobileMenu(props: Props) {
  return (
    <nav className={classes.container}>
      <Link href="/">
        <AnakinworldLineLogo />
      </Link>
      {props.items.map((category) => {
        return (
          <div key={category.label}>
            <span className={classes.categoryName}>{category.label}</span>
            <ul className={classes.items}>
              {category.items.map((item) => (
                <li key={item.label}>
                  <Link
                    href={item.path}
                    className={classes.link}
                    onClick={() => GtmFireEvent(GtmCategory.CTAClick, GtmAction.MobileMainMenuItem, item.label)}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        );
      })}
      <div className={classes.networks}>
        <Link href="https://twitter.com/anakinworld">
          <TwitterIcon />
        </Link>
        <Link href="https://www.facebook.com/anakinworld">
          <FacebookIcon />
        </Link>
        <Link href="https://www.instagram.com/sw_anakinworld/">
          <InstagramIcon />
        </Link>
      </div>
    </nav>
  );
}

export default MobileMenu;
