import DesktopHeader from '@/components/ui/Layout/Header/Desktop/DesktopHeader';
import MobileHeader from '@/components/ui/Layout/Header/Mobile/MobileHeader';
import { MenuCategory } from '@/hooks/useMenu';

type Props = {
  isMobile: boolean;
  displaySearch?: boolean;
  menu: Array<MenuCategory>;
  toggleMenu?: () => void;
};

function Header(props: Props) {
  if (props.isMobile) {
    return <MobileHeader toggleMenu={props.toggleMenu} displaySearch={props.displaySearch} />;
  }

  return <DesktopHeader menu={props.menu} displaySearch={props.displaySearch} />;
}

export default Header;
