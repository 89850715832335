import { ReactNode } from 'react';
import classes from './VerticalList.module.css';

type Props = {
  children: ReactNode;
};

function VerticalList(props: Props) {
  return <div className={classes.container}>{props.children}</div>;
}

export default VerticalList;
