function Bb8Icon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="570.000000pt"
      height="708.000000pt"
      viewBox="0 0 570.000000 708.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,708.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M2900 6015 c-10 -12 -15 -92 -21 -293 -4 -152 -7 -278 -8 -280 -1 -2
-13 -2 -28 0 -27 3 -28 5 -33 74 -6 80 -21 106 -59 102 -33 -4 -49 -37 -56
-120 l-6 -67 -56 -11 c-355 -74 -631 -330 -739 -685 -24 -81 -27 -106 -28
-245 -1 -218 -3 -212 104 -295 50 -38 90 -72 90 -75 0 -3 -26 -20 -58 -39
-317 -191 -573 -506 -695 -858 -171 -490 -104 -1017 182 -1445 207 -309 497
-531 851 -649 322 -107 698 -107 1020 0 354 118 644 340 851 649 516 770 285
1810 -511 2302 -30 19 -57 35 -59 37 -2 1 22 33 54 70 31 37 62 78 68 92 7 16
11 93 12 191 0 131 -4 179 -19 235 -90 335 -332 588 -658 690 l-86 27 -6 81
c-3 45 -8 176 -11 292 -4 125 -10 214 -17 222 -14 18 -63 16 -78 -2z m116
-775 c157 -39 322 -144 420 -268 56 -71 129 -216 151 -302 10 -40 17 -107 17
-175 l1 -110 -785 0 -785 0 1 110 c1 132 26 232 85 345 102 197 285 343 500
400 104 27 288 27 395 0z m564 -980 c0 -10 -10 -28 -22 -40 -21 -20 -31 -20
-706 -20 -426 0 -690 4 -702 10 -10 5 -23 23 -28 40 l-11 30 735 0 c722 0 734
0 734 -20z m-235 -188 c39 -13 118 -49 176 -78 l106 -54 6 -83 c3 -45 4 -83 3
-84 -1 -2 -25 4 -52 11 -112 33 -232 49 -370 50 l-142 1 -23 44 c-34 65 -103
136 -172 178 l-61 38 205 5 c231 6 229 6 324 -28z m-707 -97 c44 -18 54 -42
28 -64 -29 -24 -25 -51 16 -112 44 -66 49 -67 140 -44 35 9 48 -15 55 -103 6
-87 -16 -192 -61 -285 -40 -84 -105 -177 -124 -177 -7 0 -31 12 -53 27 -40 26
-41 26 -72 10 -49 -27 -117 -94 -117 -116 0 -11 7 -36 16 -55 9 -19 14 -41 11
-50 -14 -37 -201 -109 -328 -126 -121 -17 -299 14 -299 52 0 7 9 28 20 46 11
18 20 41 20 51 0 18 -100 121 -118 121 -5 0 -21 -9 -36 -21 -60 -47 -91 11
-83 156 9 141 61 265 163 389 55 67 81 79 104 51 22 -27 62 -17 115 29 l50 44
-13 36 c-11 31 -11 40 0 53 18 23 128 71 212 94 92 25 288 22 354 -6z m1425
-452 c31 -48 51 -89 57 -125 21 -108 69 -224 133 -317 33 -49 53 -125 62 -246
l6 -80 -36 40 c-45 49 -175 244 -175 263 0 8 9 25 20 39 l19 25 -26 51 c-29
59 -40 69 -66 63 -11 -3 -25 -2 -33 1 -29 11 -137 338 -157 478 l-6 40 77 -81
c42 -44 98 -112 125 -151z m-635 161 c51 -9 125 -26 164 -38 l70 -21 34 -122
c62 -228 160 -439 301 -652 l84 -126 -17 -50 c-30 -87 -81 -192 -129 -267 -56
-87 -153 -208 -167 -208 -5 0 -28 13 -51 29 -255 174 -670 222 -1009 115 -46
-15 -109 -40 -142 -56 -32 -16 -64 -27 -71 -24 -32 12 -161 304 -180 407 -6
34 -5 36 32 47 139 42 347 177 473 308 177 184 278 396 283 593 l2 80 35 4
c57 6 188 -3 288 -19z m-1927 -496 c-2 -45 2 -107 9 -137 55 -252 289 -407
582 -386 l87 6 11 -43 c30 -120 90 -262 171 -404 l23 -42 -57 -52 c-246 -224
-264 -542 -44 -767 63 -65 49 -68 -68 -12 -443 210 -755 637 -826 1128 -15
107 -6 409 16 506 18 81 85 285 94 285 3 0 4 -37 2 -82z m2748 -651 l62 -64
-11 -63 c-37 -207 -157 -478 -268 -609 l-29 -35 -7 53 c-10 72 -39 147 -87
222 -27 40 -38 67 -33 75 5 7 38 48 75 93 76 94 158 228 197 323 15 37 29 68
32 68 3 0 34 -29 69 -63z m-879 -377 c103 -29 203 -84 267 -146 65 -64 68 -88
13 -110 -50 -20 -58 -45 -35 -116 23 -70 31 -78 74 -78 54 0 65 -11 56 -56
-27 -146 -176 -297 -367 -374 -95 -37 -106 -37 -120 0 -14 41 -37 52 -92 41
-59 -11 -76 -26 -76 -67 0 -43 -20 -49 -145 -40 -180 13 -330 64 -434 146 -89
70 -99 109 -36 130 56 18 69 41 62 111 -7 84 -23 99 -102 99 -72 0 -79 8 -63
67 35 129 149 253 302 328 50 25 105 48 123 51 30 6 33 4 57 -44 14 -29 35
-53 47 -57 26 -8 123 3 155 18 22 10 44 58 44 98 0 9 3 19 8 23 12 14 184 -2
262 -24z"
        />
        <path
          d="M2757 5116 c-159 -57 -217 -253 -114 -388 144 -188 446 -85 447 152
0 171 -172 293 -333 236z"
        />
        <path
          d="M3249 4681 c-39 -40 -41 -97 -3 -135 58 -57 164 -14 164 66 -1 85
-102 128 -161 69z"
        />
        <path
          d="M2230 3767 c-89 -30 -147 -66 -212 -131 -170 -170 -174 -394 -8 -479
51 -26 62 -28 146 -25 82 3 100 8 165 40 168 84 280 236 281 383 1 181 -174
280 -372 212z m229 -47 c138 -70 129 -262 -19 -410 -160 -159 -400 -184 -483
-50 -33 52 -30 146 5 223 32 67 114 158 178 195 107 63 243 81 319 42z"
        />
        <path
          d="M2974 1945 c-127 -27 -229 -95 -276 -181 -37 -68 -43 -112 -24 -176
39 -137 196 -228 391 -228 295 0 499 208 399 408 -72 145 -285 222 -490 177z
m241 -49 c144 -38 229 -124 229 -231 0 -125 -124 -229 -305 -256 -221 -33
-429 83 -429 239 0 83 80 185 176 223 100 40 236 50 329 25z"
        />
      </g>
    </svg>
  );
}

export default Bb8Icon;
