import DiscordIcon from '@/components/icons/DiscordIcon';
import FacebookIcon from '@/components/icons/FacebookIcon';
import InstagramIcon from '@/components/icons/InstagramIcon';
import TwitterIcon from '@/components/icons/TwitterIcon';
import Link from 'next/link';
import classes from './Footer.module.css';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.container}>
      <div className={classes.networks}>
        <Link href="https://twitter.com/anakinworld" title="Rejoignez-nous sur Twitter">
          <TwitterIcon />
        </Link>

        <Link href="https://www.facebook.com/anakinworld" title="Rejoignez-nous sur Facebook">
          <FacebookIcon />
        </Link>

        <Link href="https://discord.gg/Nh3URNKaA3" title="Rejoignez-nous sur Discord">
          <DiscordIcon />
        </Link>

        <Link href="https://www.instagram.com/sw_anakinworld/" title="Rejoignez-nous sur Instagram">
          <InstagramIcon />
        </Link>
      </div>
      <div className={classes.links}>
        <Link href="https://discord.gg/Nh3URNKaA3">Contact & Recrutement sur Discord</Link>
      </div>
      <div className={classes.copyright}>
        Développé par{' '}
        <Link href="https://www.codartesien.fr" target="_blank">
          Codartesien
        </Link>{' '}
        &copy; 2003-{currentYear} Anakinworld.com.
      </div>
    </footer>
  );
}

export default Footer;
