function MovieIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5156 8.12503H4.07812L17.4062 5.46878C17.7383 5.40628 17.9531 5.08988 17.8906 4.76175L17.543 2.98832C17.4805 2.66019 17.1602 2.44925 16.8281 2.51175L2.37109 5.38675C2.03906 5.44925 1.82422 5.76566 1.88672 6.08988L2.23438 7.86332C2.25781 7.97269 2.31641 8.06644 2.38672 8.14457C2.09766 8.19144 1.875 8.42582 1.875 8.7266V16.8985C1.875 17.2305 2.14844 17.5 2.48437 17.5H17.5156C17.8516 17.5 18.125 17.2305 18.125 16.8985V8.7266C18.125 8.39457 17.8516 8.12503 17.5156 8.12503ZM11.9141 15.7188L9.93359 14.3008L7.95312 15.7188L8.71484 13.4375L6.73047 12.0313H9.17969L9.92969 9.75003L10.6836 12.0313H13.1328L11.1484 13.4375L11.9141 15.7188Z"
        fill="white"
      />
    </svg>
  );
}

export default MovieIcon;
