function AnakinworldLineLogo() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="3116.000000pt"
      height="593.000000pt"
      viewBox="0 0 3116.000000 593.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,593.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M2775 5434 c-515 -54 -934 -219 -1325 -522 -106 -83 -306 -281 -390
-387 -274 -346 -448 -748 -517 -1195 -25 -160 -25 -520 0 -680 57 -368 183
-704 375 -995 109 -167 186 -261 326 -400 133 -132 239 -218 391 -318 98 -64
283 -167 300 -167 5 0 -25 23 -66 51 -172 118 -382 314 -509 474 -274 345
-448 748 -517 1195 -25 160 -25 520 0 680 69 447 243 849 517 1195 84 106 284
304 390 387 352 273 734 436 1180 505 84 13 175 18 340 18 241 0 359 -13 560
-61 110 -26 264 -74 339 -106 75 -32 57 -12 -36 38 -236 129 -527 225 -813
269 -101 16 -459 28 -545 19z"
        />
        <path
          d="M12664 5046 c-335 -89 -598 -292 -745 -579 -97 -188 -129 -308 -494
-1832 l-184 -770 28 -40 29 -40 191 -3 c191 -2 191 -2 201 20 5 13 52 216 105
453 53 236 98 431 100 434 7 6 55 -52 107 -129 57 -86 122 -217 233 -475 108
-251 225 -500 273 -582 142 -242 321 -423 504 -507 245 -114 556 -106 742 17
166 111 247 271 222 440 -16 109 -122 195 -227 185 -98 -10 -179 -98 -179
-195 0 -24 9 -70 21 -103 29 -85 24 -100 -41 -133 -50 -26 -62 -28 -140 -25
-248 11 -405 196 -635 743 -98 234 -204 447 -276 554 -61 92 -184 227 -243
268 l-29 20 99 17 c124 22 290 76 371 122 189 105 329 276 364 442 17 85 8
197 -23 264 -32 69 -96 135 -163 168 -70 35 -207 35 -315 2 -137 -43 -286
-130 -425 -248 -43 -37 -65 -50 -62 -38 3 10 45 191 92 402 121 540 153 644
224 725 49 55 82 60 231 37 270 -43 373 -37 455 25 91 70 103 222 23 307 -53
55 -103 72 -228 75 -96 3 -130 -1 -206 -21z m-52 -1593 c43 -39 45 -136 5
-223 -64 -136 -271 -228 -554 -247 l-103 -6 0 28 c0 22 22 51 108 137 264 269
464 384 544 311z"
        />
        <path
          d="M14795 4761 c-88 -24 -152 -76 -192 -155 -31 -61 -42 -173 -23 -230
28 -87 117 -149 210 -149 72 1 118 21 179 78 179 167 120 437 -99 459 -25 3
-58 1 -75 -3z"
        />
        <path
          d="M4960 4694 c-25 -19 -69 -43 -98 -54 -51 -19 -210 -42 -382 -55 -503
-37 -782 -138 -983 -355 -183 -198 -251 -518 -143 -678 83 -123 261 -128 342
-10 42 59 42 115 4 227 -25 71 -31 104 -28 140 10 106 100 223 217 285 91 48
183 70 388 95 92 12 185 23 206 26 l37 5 -93 -117 c-227 -286 -450 -602 -873
-1238 -581 -875 -814 -1163 -1028 -1270 -81 -41 -119 -44 -255 -15 -120 25
-164 25 -219 -1 -115 -56 -137 -229 -42 -328 55 -57 133 -83 245 -84 115 0
194 20 320 83 262 130 471 360 913 1006 l152 221 46 7 c26 3 186 6 355 6 l309
0 -5 -27 c-3 -16 -14 -92 -25 -170 -22 -149 -68 -332 -118 -470 l-30 -82 31
-41 c32 -41 33 -42 82 -36 140 17 307 17 451 2 152 -16 358 -21 425 -10 l36 6
15 89 14 88 -35 35 -36 34 -90 -11 c-84 -10 -259 0 -306 18 -13 5 -9 61 33
443 53 491 216 1718 255 1917 13 72 33 166 44 210 10 44 15 82 11 86 -4 3 -27
18 -52 32 l-45 25 -45 -34z m-451 -926 c-23 -125 -106 -696 -115 -793 -4 -38
-11 -76 -17 -82 -7 -10 -66 -13 -231 -13 -121 0 -238 3 -258 6 l-37 7 256 381
c224 333 408 596 418 596 1 0 -6 -46 -16 -102z"
        />
        <path
          d="M10365 3854 c-33 -8 -70 -16 -81 -19 -12 -3 -31 -21 -43 -40 l-20
-35 -117 0 c-371 0 -607 -102 -890 -384 -448 -448 -671 -1109 -493 -1465 98
-197 290 -242 535 -127 86 41 232 137 320 212 35 30 64 53 64 51 0 -2 -7 -27
-16 -56 -77 -259 122 -348 441 -197 108 51 178 96 266 167 80 66 309 271 309
277 0 3 -61 79 -100 123 -3 4 -25 9 -50 13 -44 6 -47 4 -110 -50 -69 -59 -183
-140 -239 -169 l-34 -17 7 64 c24 210 156 762 295 1232 56 186 101 344 101
350 0 12 -71 87 -80 85 -3 0 -32 -7 -65 -15z m-440 -472 c20 -7 18 -19 -70
-347 -96 -362 -119 -432 -170 -535 -96 -191 -289 -350 -425 -350 -48 0 -61 4
-84 26 -14 15 -30 43 -36 63 -15 54 -12 247 5 347 41 236 181 531 314 664 54
54 149 107 226 126 53 13 206 17 240 6z"
        />
        <path
          d="M22340 3833 c-244 -18 -490 -97 -643 -207 -86 -60 -197 -180 -245
-262 -91 -159 -130 -307 -138 -529 -23 -607 285 -984 881 -1081 143 -23 429
-23 565 -1 438 74 716 287 836 641 42 122 58 229 57 391 0 209 -33 366 -108
515 -97 194 -282 357 -500 441 -191 74 -466 110 -705 92z m272 -634 c103 -22
155 -63 202 -156 57 -114 73 -282 42 -432 -26 -123 -73 -184 -182 -233 -39
-17 -73 -23 -170 -26 -132 -5 -211 10 -269 51 -40 28 -50 41 -86 111 -81 155
-75 420 12 556 40 65 107 110 186 129 55 13 203 13 265 0z"
        />
        <path
          d="M18418 3796 c-16 -22 -7 -53 268 -957 157 -514 294 -964 305 -1001
l20 -68 352 0 352 0 91 362 90 363 98 -330 c54 -182 103 -345 108 -363 l9 -32
353 0 353 0 251 1001 c241 965 250 1003 234 1025 l-17 23 -341 1 -340 0 -73
-342 c-40 -189 -75 -353 -79 -366 -8 -24 -16 -1 -172 486 l-72 222 -326 -2
-325 -3 -103 -370 c-74 -268 -104 -364 -110 -350 -5 11 -58 178 -119 370
l-112 350 -339 2 -339 3 -17 -24z"
        />
        <path
          d="M23932 3797 l-22 -23 0 -980 0 -981 23 -21 c23 -22 27 -22 384 -22
335 0 361 1 376 18 14 16 17 55 19 292 l3 274 280 -292 280 -292 401 0 c376 0
402 1 417 18 15 16 17 53 17 314 l0 295 -23 21 c-23 22 -30 22 -308 22 l-284
1 -40 53 c-22 30 -40 57 -40 62 0 4 30 17 67 30 223 75 385 232 427 414 15 63
13 231 -3 322 -20 108 -64 187 -161 283 -109 110 -237 174 -409 204 -38 7
-303 11 -721 11 l-662 0 -21 -23z m1275 -567 c68 -41 73 -218 7 -249 -16 -7
-107 -11 -264 -11 l-240 0 0 140 0 140 233 0 c212 0 235 -2 264 -20z"
        />
        <path
          d="M26297 3802 c-16 -17 -17 -101 -17 -1009 0 -946 1 -991 18 -1006 17
-16 92 -17 879 -17 820 0 861 1 876 18 15 16 17 53 17 314 l0 295 -23 21 c-23
22 -23 22 -535 22 l-512 0 0 673 c0 640 -1 675 -18 690 -17 15 -55 17 -344 17
-302 0 -326 -1 -341 -18z"
        />
        <path
          d="M28332 3797 l-22 -23 0 -980 0 -981 23 -21 23 -22 660 0 c703 0 768
4 919 51 79 24 188 77 255 122 230 158 359 441 377 827 14 304 -70 564 -241
747 -107 115 -237 194 -406 247 -165 53 -220 56 -921 56 l-646 0 -21 -23z
m1177 -563 c61 -18 138 -83 176 -149 35 -59 55 -156 55 -272 0 -233 -57 -364
-192 -439 l-53 -29 -222 -3 -223 -4 0 456 0 456 203 0 c156 0 216 -4 256 -16z"
        />
        <path
          d="M6714 3796 c-174 -40 -334 -138 -583 -360 l-111 -99 0 -46 c0 -40 5
-52 39 -89 34 -37 44 -42 81 -42 35 0 48 6 74 34 87 94 266 229 266 201 0 -26
-268 -1162 -319 -1350 l-48 -180 25 -35 c14 -19 31 -38 36 -42 6 -4 95 -8 197
-8 l187 0 7 28 c4 15 53 227 110 472 109 471 143 583 205 679 109 169 389 391
523 416 34 7 37 6 37 -17 0 -39 -55 -249 -130 -498 -186 -612 -224 -850 -160
-998 38 -87 120 -142 213 -142 137 0 364 140 602 372 l140 136 -18 34 c-38 69
-69 98 -117 104 -45 5 -45 5 -150 -85 -131 -112 -219 -181 -232 -181 -18 0 -1
123 36 275 19 77 76 273 126 435 138 450 170 572 177 677 5 72 2 103 -11 145
-59 181 -229 221 -461 110 -121 -59 -368 -232 -492 -346 -24 -21 -43 -34 -43
-28 0 6 14 72 30 145 35 158 36 181 10 224 -38 63 -131 85 -246 59z"
        />
        <path
          d="M14470 3795 c-80 -22 -264 -116 -345 -177 -38 -29 -123 -104 -188
-168 l-118 -115 7 -48 c7 -46 11 -52 77 -102 l71 -53 115 110 c113 108 197
175 207 165 3 -3 -29 -119 -71 -258 -191 -639 -282 -1037 -273 -1195 10 -163
124 -254 283 -227 150 26 317 134 578 374 97 90 97 91 97 138 0 47 -2 50 -69
109 l-68 61 -29 -31 c-79 -83 -308 -268 -318 -257 -7 7 23 173 58 314 19 77
74 275 121 440 219 754 223 776 173 852 -14 22 -43 48 -65 59 -50 24 -170 29
-243 9z"
        />
        <path
          d="M16224 3796 c-174 -40 -337 -141 -584 -361 -110 -99 -110 -99 -110
-145 0 -41 5 -50 43 -88 35 -35 49 -42 81 -42 35 0 48 8 125 83 106 101 211
176 211 151 0 -38 -282 -1223 -343 -1445 l-24 -86 34 -42 35 -41 188 0 c171 0
189 2 194 18 2 9 50 215 106 457 108 467 146 600 201 691 100 166 394 404 532
429 35 7 37 6 37 -19 0 -39 -52 -240 -125 -481 -132 -438 -195 -705 -195 -827
0 -176 61 -286 176 -318 152 -43 386 85 676 369 l137 134 -42 61 c-38 55 -47
62 -89 70 -53 10 -55 9 -225 -141 -94 -83 -160 -129 -170 -119 -22 22 45 312
157 671 158 513 190 636 190 744 0 258 -193 355 -459 231 -121 -57 -297 -177
-458 -312 -51 -43 -93 -74 -93 -69 0 5 14 68 30 141 35 155 37 184 10 227 -38
63 -131 85 -246 59z"
        />
      </g>
    </svg>
  );
}

export default AnakinworldLineLogo;
