import ChronologyIcon from '@/components/icons/ChronologyIcon';
import ComicsIcon from '@/components/icons/ComicsIcon';
import GameIcon from '@/components/icons/GameIcon';
import MovieIcon from '@/components/icons/MovieIcon';
import NovelIcon from '@/components/icons/NovelIcon';
import StarWarsIcon from '@/components/icons/StarWarsIcon';
import TvShowIcon from '@/components/icons/TvShowIcon';
import { HighlightItem } from '@/types/model';
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage';
import { resolveUrl } from '@/utils/routes';
import { doSearchHighlight } from 'api/search';
import { ReactNode, useEffect, useState } from 'react';

const MENU_TTL = 1000 * 600; // 10 minutes

export type MenuItem = {
  label: string;
  path: string;
  icon?: ReactNode;
};

export type MenuCategory = {
  label: string;
  items: Array<MenuItem>;
};

const staticMenu: Array<MenuCategory> = [
  {
    label: 'Oeuvres',
    items: [
      {
        path: '/films',
        icon: <MovieIcon />,
        label: 'Films',
      },
      {
        path: '/series-tv',
        icon: <TvShowIcon />,
        label: 'Séries TV',
      },
      {
        path: '/jeux-video',
        icon: <GameIcon />,
        label: 'Jeux Vidéo',
      },
      {
        path: '/bandes-dessinees',
        icon: <ComicsIcon />,
        label: 'BD & Comics',
      },
      {
        path: '/romans',
        icon: <NovelIcon />,
        label: 'Romans',
      },
    ],
  },
  {
    label: 'Découvrir',
    items: [
      {
        path: '/encyclopedie',
        icon: <StarWarsIcon />,
        label: "L'univers Star Wars",
      },
      {
        path: '/encyclopedie/listes/chronologie',
        icon: <ChronologyIcon />,
        label: 'Chronologie',
      },
      // {
      //   path: '/',
      //   icon: <AtlasIcon />,
      //   label: 'Atlas Galactique',
      // },
    ],
  },
  {
    label: 'Partager',
    items: [
      {
        path: 'https://discord.gg/Nh3URNKaA3',
        label: 'Discord',
      },
    ],
  },
];

const useMenu = (): Array<MenuCategory> => {
  const [menu, setMenu] = useState<Array<MenuCategory>>(staticMenu);

  useEffect(() => {
    const executeSearch = () => {
      doSearchHighlight()
        .then((res) => {
          console.log('Fetched dynamic menu');
          const highlightMenus: Array<MenuCategory> = [];
          if (res.results) {
            const highlights: Array<MenuItem> = res.results.map((item: HighlightItem): MenuItem => {
              return {
                label: item.config.label,
                path: resolveUrl(item.config.routeConfig),
              };
            });

            try {
              setLocalStorage('menu', highlights, MENU_TTL);
            } catch (err) {
              console.log(err);
            }

            if (highlights.length === 0) {
              return;
            }

            highlightMenus.push({
              label: 'Actus',
              items: highlights,
            });

            setMenu([...highlightMenus, ...staticMenu]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    let highlights = null;
    try {
      highlights = getLocalStorage('menu');
    } catch (err) {
      console.log(err);
    }

    if (highlights === null) {
      executeSearch();
    } else {
      const menu = [];
      if (highlights && highlights.length > 0) {
        menu.push({
          label: 'Actus',
          items: highlights,
        });
      }

      setMenu([...menu, ...staticMenu]);
    }
  }, [setMenu]);

  return menu;
};

export default useMenu;
