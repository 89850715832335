import { createContext, ReactNode, useState } from 'react';

// Context contains values and an update state function to allow components using the context to update its values.
type GalleryContextProps = {
  isOpened: boolean;
  pictureUrls: string[];
  currentIndex?: number;
  updateState: (updatedState: Partial<GalleryContextProps>) => void;
};

export const GalleryContext = createContext<GalleryContextProps>(undefined);

export type GalleryProviderProps = {
  children?: ReactNode;
};

// The provider is a statefull component wrapping the default context provider.
// It handles logic to update the context values through its state
export const GalleryProvider = ({ children }: GalleryProviderProps) => {
  const [state, setState] = useState({
    isOpened: false,
    pictureUrls: [],
  });

  const updateState = (updatedState: Partial<GalleryContextProps>) => {
    setState({ ...state, ...updatedState });
  };

  return <GalleryContext.Provider value={{ ...state, updateState }}>{children}</GalleryContext.Provider>;
};
