import { CalculatedVersions, Universe, VersionDocument } from '@/types/documents';
import { EntryListItem, RouteType, SourceListItem, Tag, UrlDefinition } from '@/types/model';
import { getConfigValue } from '@/utils/config';
import { entryTypes, EntryTypes } from '@/utils/references/entryTypes';
import { Era } from '@/utils/references/eras';
import {
  SourceTypes,
  TYPE_COMIC,
  TYPE_COMIC_SERIE,
  TYPE_GAME,
  TYPE_MOVIE,
  TYPE_NOVEL,
  TYPE_TVSHOW,
  TYPE_TVSHOW_SEASON,
} from '@/utils/references/sourceTypes';

export function getPathToVersionShow(version: VersionDocument): string {
  if (version.calculatedVersions === CalculatedVersions.All && version.universe === Universe.Legends) {
    return `/encyclopedie/${version.slug}/legends`;
  }

  return `/encyclopedie/${version.slug}`;
}

export function getPathToVersion(item: EntryListItem): string {
  if (item.version && item.calculatedVersions === CalculatedVersions.All && item.version === Universe.Legends) {
    return `/encyclopedie/${item.slug}/legends`;
  }

  return `/encyclopedie/${item.slug}`;
}

export function getPathToEntryList(type: EntryTypes): string {
  const typeObject = entryTypes.get(type);

  return `/encyclopedie/listes/${typeObject.slug}`;
}

export function getPathToEntryListByTag(tag: Tag): string {
  return `/encyclopedie/listes/${tag.slug}`;
}

export function getPathToEraList(universe): string {
  return `/encyclopedie/listes/chronologie${universe === Universe.Canon ? '' : '/legends'}`;
}

export function getPathToEra(era: Era): string {
  return `/encyclopedie/listes/chronologie/${era.slug}`;
}

export function getPathToSource(source: SourceListItem): string {
  switch (source.type.slug) {
    case TYPE_MOVIE:
      return `/films/${source.slug}`;
    case TYPE_NOVEL:
      return `/romans/${source.slug}`;
    case TYPE_COMIC_SERIE:
      return `/bandes-dessinees/${source.slug}`;
    case TYPE_COMIC:
      return `/bandes-dessinees/${source.serie.slug}/${source.slug}`;
    case TYPE_TVSHOW:
      return `/series-tv/${source.slug}`;
    case TYPE_TVSHOW_SEASON:
      return `/series-tv/${source.serie.slug}/${source.slug}`;
    case TYPE_GAME:
      return `/jeux-video/${source.slug}`;
    // case TYPE_SOURCEBOOK:
    //   return `/sourcebooks/${source.slug}`;
  }

  throw new Error(`Could not create path for source of type ${source.type}`);
}

export function sourceHasPath(source: SourceListItem): boolean {
  try {
    return getPathToSource(source) !== undefined;
  } catch (e) {
    return false;
  }
}

export function getPathToSourceList(type: SourceTypes): string {
  switch (type) {
    case TYPE_MOVIE:
      return `/films`;
    case TYPE_NOVEL:
      return `/romans`;
    case TYPE_COMIC:
    case TYPE_COMIC_SERIE:
      return `/bandes-dessinees`;
    case TYPE_TVSHOW:
    case TYPE_TVSHOW_SEASON:
      return `/series-tv`;
    case TYPE_GAME:
      return `/jeux-video`;
  }

  throw new Error(`Could not create path for list source of type ${type}`);
}

export function resolveUrl(definition: UrlDefinition) {
  const { routeType, resolvedTarget } = definition;

  if (routeType === RouteType.RouteTypeHref) {
    return resolvedTarget;
  }

  if (!resolvedTarget) {
    throw new Error('Could not resolve url with unresolved target');
  }

  if (routeType === RouteType.RouteTypeVersion) {
    return getPathToVersion(resolvedTarget as EntryListItem);
  }

  const sourceRouteTypes = [
    RouteType.RouteTypeSourceMovie,
    RouteType.RouteTypeSourceTvShowSerie,
    RouteType.RouteTypeSourceTvShowSeason,
    RouteType.RouteTypeSourceComic,
    RouteType.RouteTypeSourceComicSerie,
    RouteType.RouteTypeSourceNovel,
    RouteType.RouteTypeSourceGame,
  ];

  if (sourceRouteTypes.includes(routeType)) {
    return getPathToSource(resolvedTarget as SourceListItem);
  }

  throw new Error(`Could not resolve url with unknown route type "${routeType}"`);
}

export function getHost(): string {
  return getConfigValue('siteHost') as string;
}
