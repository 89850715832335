import DescriptionItem from '@/components/ui/List/Item/DescriptionItem';
import VerticalList from '@/components/ui/List/VerticalList';
import SectionTitle from '@/components/ui/Title/SectionTitle';
import { EntryListItem } from '@/types/model';
import { MouseEventHandler, Ref } from 'react';
import classes from './VerticalEntryList.module.css';

type Props = {
  title?: string;
  items: Array<EntryListItem>;
  lastItemRef?: Ref<any>;
  onItemClick?: MouseEventHandler;
};

function VerticalEntryList(props: Props) {
  const { title, items, lastItemRef, onItemClick } = props;

  return (
    <section className={classes.container}>
      {title && <SectionTitle>{title}</SectionTitle>}
      <VerticalList>
        {items.map((item, i) => (
          <DescriptionItem
            key={i}
            title={item.name}
            href={`/encyclopedie/${item.slug}`}
            subtitle={item.type.name}
            picture={item.image}
            description={item.description}
            lastItemRef={items.length === i + 1 ? lastItemRef : null}
            onClick={onItemClick}
          />
        ))}
      </VerticalList>
    </section>
  );
}

export default VerticalEntryList;
