function TvShowIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.92004 9.55333V14.1133H5.74004H9.56004V14.5933V15.0733H7.02004H4.48004V15.4933V15.9133H10H15.52V15.4933V15.0733H12.98H10.44V14.5933V14.1133H14.3H18.16V9.55333V4.99333H10.04H1.92004V9.55333ZM10.504 8.38133C12.084 9.29333 12.548 9.58133 12.512 9.61733C12.484 9.64133 11.604 10.1533 10.56 10.7533C9.51604 11.3573 8.60004 11.8813 8.53204 11.9253L8.40004 12.0013V9.59733C8.40004 8.27333 8.40804 7.19333 8.42404 7.19333C8.43604 7.19333 9.37204 7.72933 10.504 8.38133Z"
        fill="white"
      />
    </svg>
  );
}

export default TvShowIcon;
