type Props = {
  firstLineText: string;
  secondLineText?: string;
};

function NoPicture(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="400" width="400" viewBox="0 0 400 400">
      <defs>
        <linearGradient y2="109.13" x2="613.203" y1="6.953" x1="614.812" id="A" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#c2c2c2" />
          <stop offset="1" stopColor="#9f9f9f" />
        </linearGradient>
      </defs>
      <g transform="translate(-399.134 -122.791)">
        <rect ry="7.014" y="122.791" x="399.134" height="400" width="400" fill="#eee" />
        <g transform="translate(90.778 191.042)">
          <rect
            width="82.49"
            height="90.47"
            x="398.758"
            y="178.747"
            ry="8.397"
            transform="rotate(343.657)"
            fill="#9f9f9f"
          />
          <g transform="rotate(16.321 538.136 -184.897)" stroke="#fff">
            <rect
              ry="4.596"
              y="1.65"
              x="547.186"
              height="115.966"
              width="107.834"
              fill="url(#A)"
              strokeWidth="5.398"
              strokeLinejoin="round"
            />
            <g fillRule="evenodd" strokeLinejoin="round" strokeWidth="2.257">
              <path d="M559.11 12.207h84.792v77.694H559.11z" fill="#ccc" />
              <path d="M559.11 12.207h84.792v36.258H559.11z" fill="#fff" />
              <path
                d="M564.29 48.465l6.641-9.244 10.451-7.797 2.742 3.207 2.35-6.679 1.416-.515 2.298-4.87 31.078 31.078"
                fill="#ccc"
                strokeLinecap="round"
                paintOrder="stroke"
              />
              <path
                d="M590.188 22.566l1.874 8.59 1.091 6.583-3.81-1.236-7.961-5.079.919 8.016-.279 4.28 1.736 5.245 12.636-2.03 14.513-3.649-8.253-13.369-1.675 2.578-4.683-3.82z"
                fill="#b3b3b3"
                paintOrder="stroke"
              />
              <path
                d="M585.008 53.644l14.14-12.851 3.195-1.671 4.083-7.629 4.479-3.747 31.078 31.078"
                fill="#ccc"
                strokeLinecap="round"
                paintOrder="stroke"
              />
              <path
                d="M610.907 27.746l6.205 13.97v5.199 5.199l3.878 3.361a14.08 14.08 0 0 1 3.875-2.712c2.56-1.209 5.411-1.616 8.144-2.352 1.11-.299 2.228-.665 3.145-1.359a5.15 5.15 0 0 0 .652-.587h-5.18l-6.996-7.001-5.155-8.446-1.77 1.526z"
                fill="#b3b3b3"
                paintOrder="stroke"
              />
              <path
                d="M585.008 89.902c-.072-1.658-1.522-3.04 2.957-5.295 5.496-2.767 21.736-6.196 26.27-10.357 3.354-3.078-12.158-6.504-9.499-10.199 1.205-1.676 10.193-3.448 14.183-5.227 3.782-1.686 2.614-3.419 5.422-5.175 2.712-1.696 11.801-3.414 12.464-5.184-1.798 1.817-11.884 3.523-15.539 5.18-3.857 1.749-3.707 3.441-8.535 5.091-5.201 1.777-15.391 3.549-17.733 5.269-4.906 3.603 8.091 7.355 2.797 10.359-7.169 4.068-25.785 7.475-33.506 10.359-5.336 1.993-4.905 3.064-5.18 5.18"
                fill="#fff"
                strokeLinecap="round"
                paintOrder="stroke"
              />
            </g>
          </g>
        </g>
        <text y="412.125" x="562.007" xmlSpace="preserve" fontSize="32" fontFamily="sans-serif" fill="#767676">
          <tspan y="412.125" x="600.007" fontWeight="bold" textAnchor="middle">
            {props.firstLineText.toUpperCase()}
          </tspan>
          <tspan y="452.125" x="600.007" fontWeight="bold" textAnchor="middle">
            {props.secondLineText?.toUpperCase()}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default NoPicture;
