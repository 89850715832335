function FacebookIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.00195 12.002C2.00312 16.9214 5.58036 21.11 10.439 21.881V14.892H7.90195V12.002H10.442V9.80198C10.3284 8.75952 10.6845 7.72058 11.4136 6.96692C12.1427 6.21325 13.1693 5.823 14.215 5.90198C14.9655 5.9141 15.7141 5.98095 16.455 6.10198V8.56098H15.191C14.7558 8.50399 14.3183 8.64771 14.0017 8.95165C13.6851 9.25559 13.5237 9.68687 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67035 16.7932 1.73991 11.4808 2.01716C6.16831 2.29441 2.0028 6.68229 2.00195 12.002Z"
        fill="white"
      />
    </svg>
  );
}

export default FacebookIcon;
