import BackspaceIcon from '@/components/icons/BackspaceIcon';
import CloseIcon from '@/components/icons/CloseIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import { AggregationResult } from 'api/search';
import { ChangeEventHandler, MouseEventHandler, MutableRefObject } from 'react';
import classes from './SearchField.module.css';

type Props = {
  terms?: string;
  filter?: AggregationResult;
  placeholder?: string;
  autoFocus?: boolean;
  inputChangeHandler: ChangeEventHandler<HTMLInputElement>;
  inputResetHandler: MouseEventHandler<HTMLAnchorElement>;
  filterResetHandler: MouseEventHandler<HTMLAnchorElement>;
  inputClickHandler: MouseEventHandler<HTMLInputElement>;
  inputRef: MutableRefObject<HTMLInputElement>;
};

function SearchField(props: Props) {
  const {
    terms,
    filter,
    placeholder,
    autoFocus,
    inputChangeHandler,
    inputResetHandler,
    filterResetHandler,
    inputClickHandler,
    inputRef,
  } = props;

  return (
    <div className={classes.field}>
      <SearchIcon />
      <input
        placeholder={placeholder ?? 'Rechercher un personnage, une planète...'}
        value={terms}
        onChange={inputChangeHandler}
        className={classes.input}
        onClick={inputClickHandler}
        autoFocus={autoFocus}
        ref={inputRef}
      />
      {terms.length > 0 && (
        <a className={classes.resetInput} onClick={inputResetHandler}>
          <BackspaceIcon />
        </a>
      )}
      {filter && (
        <div className={classes.filter}>
          {filter.label}
          <a onClick={filterResetHandler}>
            <CloseIcon />
          </a>
        </div>
      )}
    </div>
  );
}

export default SearchField;
