import { getHost } from '@/utils/routes';
import getConfig from 'next/config';

export function getConfigValue(key: string): unknown {
  const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
  const { isServerSide } = serverRuntimeConfig;

  return isServerSide ? serverRuntimeConfig[key] : publicRuntimeConfig[key];
}

interface StaticConfig {
  website: {
    name: string;
    description: string;
    url: string;
    logo: string;
    socialNetworks: {
      twitter: string;
      facebook: string;
    };
  };
}

export function getStaticConfig(): StaticConfig {
  return {
    website: {
      name: 'Anakinworld',
      description:
        "L'Encyclopédie Star Wars : Plus de 11000 références ! Une Encyclopédie pour un nouveau regard sur l'Univers Etendu !",
      url: getHost(),
      logo: `${getHost()}/assets/logo/logo_square.svg`,
      socialNetworks: {
        twitter: 'https://twitter.com/anakinworld',
        facebook: 'https://www.facebook.com/anakinworld',
      },
    },
  };
}
