import FilterIcon from '@/components/icons/FilterIcon';
import { AggregationResult } from 'api/search';
import classes from './SearchFilters.module.css';

type Props = {
  filters: Array<AggregationResult>;
  onFilterSelect: (filter: AggregationResult) => void;
};

function SearchFilters(props: Props) {
  return (
    <ul className={classes.list}>
      {props.filters.map((filter) => (
        <li key={filter.value} className={classes.item} onClick={() => props.onFilterSelect(filter)}>
          <FilterIcon />
          <a>{filter.label}</a>
          {filter.count && <div className={classes.count}>{filter.count}</div>}
        </li>
      ))}
    </ul>
  );
}

export default SearchFilters;
