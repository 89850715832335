import BurgerIcon from '@/components/icons/BurgerIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import AnakinworldLineLogo from '@/components/icons/logos/AnakinworldLineLogo';
import Search from '@/components/ui/Block/Search/Search';
import { SearchType } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import Link from 'next/link';
import { useState } from 'react';
import styles from './MobileHeader.module.css';

type Props = {
  displaySearch?: boolean;
  toggleMenu?: () => void;
};

function MobileHeader(props: Props) {
  const [displaySearch, setDisplaySearch] = useState<boolean>(false);

  const handleBurgerClick = () => {
    props.toggleMenu && props.toggleMenu();
  };

  const handleSearchClick = () => {
    GtmFireEvent(GtmCategory.CTAClick, GtmAction.MenuSearchField);
    setDisplaySearch(true);
  };

  const handleSearchClose = () => {
    setDisplaySearch(false);
  };

  return (
    <header className={styles.container}>
      <div className={styles.menu}>
        <a onClick={handleBurgerClick} href="#menu" title="Afficher le menu">
          <BurgerIcon />
        </a>
      </div>
      <div className={styles.logo}>
        <Link href="/" title="Anakinworld">
          <AnakinworldLineLogo />
        </Link>
      </div>
      <div className={styles.lenseBtn}>
        <a onClick={handleSearchClick} href="#search" title="Rechercher un contenu">
          <SearchIcon />
        </a>
        {displaySearch && (
          <Search
            searchType={SearchType.Entry}
            alwaysFullViewport={true}
            onFullViewportClose={handleSearchClose}
            context="menu"
          />
        )}
      </div>
    </header>
  );
}

export default MobileHeader;
