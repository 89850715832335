import SearchIcon from '@/components/icons/SearchIcon';
import AnakinworldLineLogo from '@/components/icons/logos/AnakinworldLineLogo';
import Search from '@/components/ui/Block/Search/Search';
import useKeypress from '@/hooks/useKeypress';
import { MenuCategory } from '@/hooks/useMenu';
import { SearchType } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import Link from 'next/link';
import { useState } from 'react';
import classes from './DesktopHeader.module.css';

type Props = {
  menu: Array<MenuCategory>;
  displaySearch?: boolean;
};

function DesktopHeader(props: Props) {
  const [displaySearch, setDisplaySearch] = useState<boolean>(false);

  // Open search when hitting cmd/ctrl+k key
  useKeypress(
    'k',
    () => {
      if (!displaySearch) {
        GtmFireEvent(GtmCategory.CTAClick, GtmAction.KeyboardShortcutSearchField);
        setDisplaySearch(true);
        // inputRef.current && inputRef.current.focus();
      }
    },
    [displaySearch],
    { ctrl: true },
  );

  const handleSearchClick = () => {
    GtmFireEvent(GtmCategory.CTAClick, GtmAction.MenuSearchField);
    setDisplaySearch(true);
  };

  const handleSearchClose = () => {
    setDisplaySearch(false);
  };

  return (
    <header className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.logo}>
          <Link href="/" title="Anakinworld">
            <AnakinworldLineLogo />
          </Link>
        </div>
        <div className={classes.menu}>
          {props.menu.map((category) => {
            return (
              <div className={classes.category} key={category.label}>
                <span className={classes.categoryName}>{category.label}</span>
                <ul className={classes.items}>
                  {category.items.map((item) => (
                    <li key={item.label}>
                      <Link
                        href={item.path}
                        className={classes.link}
                        onClick={() => GtmFireEvent(GtmCategory.CTAClick, GtmAction.DesktopMainMenuItem, item.label)}
                      >
                        {item.icon}
                        <span>{item.label}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
        <div className={classes.lenseBtn}>
          <a onClick={handleSearchClick} href="#search" title="Rechercher un contenu">
            <SearchIcon />
          </a>
          {displaySearch && (
            <Search
              searchType={SearchType.Entry}
              alwaysFullViewport={true}
              onFullViewportClose={handleSearchClose}
              context="menu"
            />
          )}
        </div>
      </div>
    </header>
  );
}

export default DesktopHeader;
