function FilterIcon() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 3.16669C3.34 4.89335 6.66667 9.16669 6.66667 9.16669V13.8334H9.33333V9.16669C9.33333 9.16669 12.6533 4.89335 14 3.16669H2Z"
        fill="#323232"
      />
    </svg>
  );
}

export default FilterIcon;
