import TypesData from './../../../data/types.json';

type CardSection = {
  label: string;
  data: Array<string>;
};

export type EntryType = {
  slug: string;
  listSlug?: string; // Slug used to display list of this type
  title: string; // Usable as standalone title
  name: string; // For usage in sentencres like "Tous les ..."
  nameSingular: string; // Singular of name
  isFeminine: boolean; // If name is feminine or not
  tagId: number;
  cardTemplate: Array<CardSection>;
  images: {
    tile: string;
    banner: string;
  };
};

export enum EntryTypes {
  TYPE_CHARACTER = 'personnages',
  TYPE_SPACESHIP = 'vaisseaux',
  TYPE_FACTION = 'organisations',
  TYPE_EVENT = 'evenements',
  TYPE_CHRONOLOGY = 'chronologie', // Alias used for event list
  TYPE_CULTURE = 'cultures',
  TYPE_PLANET = 'planetes',
  TYPE_WEAPON = 'armes',
  TYPE_DROID = 'droides',
  TYPE_SPECIES = 'especes',
  TYPE_CREATURE = 'creatures',
  TYPE_VEHICLE = 'vehicules',
  TYPE_OBJECT = 'objets',
  TYPE_TECHNOLOGY = 'technologies',
  TYPE_SPACE = 'espaces',
  TYPE_PLACE = 'lieux',
  TYPE_PLANT = 'plantes',
}

export const TYPE_CHARACTER = EntryTypes.TYPE_CHARACTER;
export const TYPE_SPACESHIP = EntryTypes.TYPE_SPACESHIP;
export const TYPE_FACTION = EntryTypes.TYPE_FACTION;
export const TYPE_EVENT = EntryTypes.TYPE_EVENT;
export const TYPE_CHRONOLOGY = EntryTypes.TYPE_CHRONOLOGY; // Alias used for event lists
export const TYPE_CULTURE = EntryTypes.TYPE_CULTURE;
export const TYPE_PLANET = EntryTypes.TYPE_PLANET;
export const TYPE_WEAPON = EntryTypes.TYPE_WEAPON;
export const TYPE_DROID = EntryTypes.TYPE_DROID;
export const TYPE_SPECIES = EntryTypes.TYPE_SPECIES;
export const TYPE_CREATURE = EntryTypes.TYPE_CREATURE;
export const TYPE_VEHICLE = EntryTypes.TYPE_VEHICLE;
export const TYPE_OBJECT = EntryTypes.TYPE_OBJECT;
export const TYPE_TECHNOLOGY = EntryTypes.TYPE_TECHNOLOGY;
export const TYPE_SPACE = EntryTypes.TYPE_SPACE;
export const TYPE_PLACE = EntryTypes.TYPE_PLACE;
export const TYPE_PLANT = EntryTypes.TYPE_PLANT;

export const entryTypes: Map<string, EntryType> = new Map();
entryTypes.set(TYPE_CHARACTER, {
  slug: 'personnages',
  title: 'Personnages',
  name: 'Personnages',
  nameSingular: 'Personnage',
  isFeminine: false,
  tagId: TypesData[TYPE_CHARACTER].tag,
  cardTemplate: TypesData[TYPE_CHARACTER].datalines,
  images: {
    tile: '/assets/types/tile_maxi_personnages.jpg',
    banner: '/assets/types/page_maxi_personnages.jpg',
  },
});
entryTypes.set(TYPE_SPACESHIP, {
  slug: 'vaisseaux',
  title: 'Vaisseaux',
  name: 'Vaisseaux',
  nameSingular: 'Vaisseau',
  isFeminine: false,
  tagId: TypesData[TYPE_SPACESHIP].tag,
  cardTemplate: TypesData[TYPE_SPACESHIP].datalines,
  images: {
    tile: '/assets/types/tile_maxi_vaisseaux.jpg',
    banner: '/assets/types/page_maxi_vaisseaux.jpg',
  },
});

entryTypes.set(TYPE_FACTION, {
  slug: 'organisations',
  title: 'Factions',
  name: 'Factions',
  nameSingular: 'Faction',
  isFeminine: true,
  tagId: TypesData[TYPE_FACTION].tag,
  cardTemplate: TypesData[TYPE_FACTION].datalines,
  images: {
    tile: '/assets/types/tile_maxi_organisations.jpg',
    banner: '/assets/types/page_maxi_organisations.jpg',
  },
});
entryTypes.set(TYPE_EVENT, {
  slug: 'evenements',
  listSlug: 'chronologie',
  title: 'Chronologie',
  name: 'Événements',
  nameSingular: 'Événement',
  isFeminine: false,
  tagId: TypesData[TYPE_EVENT].tag,
  cardTemplate: TypesData[TYPE_EVENT].datalines,
  images: {
    tile: '/assets/types/tile_maxi_evenements.jpg',
    banner: '/assets/types/page_maxi_evenements.jpg',
  },
});
entryTypes.set(TYPE_CULTURE, {
  slug: 'cultures',
  title: 'Éléments Culturels',
  name: 'Cultures & Société',
  nameSingular: 'Culture & Société',
  isFeminine: true,
  tagId: TypesData[TYPE_CULTURE].tag,
  cardTemplate: TypesData[TYPE_CULTURE].datalines,
  images: {
    tile: '/assets/types/tile_maxi_cultures.jpg',
    banner: '/assets/types/page_maxi_cultures.jpg',
  },
});
entryTypes.set(TYPE_PLANET, {
  slug: 'planetes',
  title: 'Planètes',
  name: 'Planètes',
  nameSingular: 'Planète',
  isFeminine: true,
  tagId: TypesData[TYPE_PLANET].tag,
  cardTemplate: TypesData[TYPE_PLANET].datalines,
  images: {
    tile: '/assets/types/tile_maxi_planetes.jpg',
    banner: '/assets/types/page_maxi_planetes.jpg',
  },
});
entryTypes.set(TYPE_WEAPON, {
  slug: 'armes',
  title: 'Armes',
  name: 'Armes',
  nameSingular: 'Arme',
  isFeminine: true,
  tagId: TypesData[TYPE_WEAPON].tag,
  cardTemplate: TypesData[TYPE_WEAPON].datalines,
  images: {
    tile: '/assets/types/tile_maxi_armes.jpg',
    banner: '/assets/types/page_maxi_armes.jpg',
  },
});
entryTypes.set(TYPE_DROID, {
  slug: 'droides',
  title: 'Droïdes',
  name: 'Droïdes',
  nameSingular: 'Droïde',
  isFeminine: false,
  tagId: TypesData[TYPE_DROID].tag,
  cardTemplate: TypesData[TYPE_DROID].datalines,
  images: {
    tile: '/assets/types/tile_maxi_droides.jpg',
    banner: '/assets/types/page_maxi_droides.jpg',
  },
});
entryTypes.set(TYPE_SPECIES, {
  slug: 'especes',
  title: 'Espèces',
  name: 'Espèces',
  nameSingular: 'Espèce',
  isFeminine: true,
  tagId: TypesData[TYPE_SPECIES].tag,
  cardTemplate: TypesData[TYPE_SPECIES].datalines,
  images: {
    tile: '/assets/types/tile_maxi_especes.jpg',
    banner: '/assets/types/page_maxi_especes.jpg',
  },
});
entryTypes.set(TYPE_CREATURE, {
  slug: 'creatures',
  title: 'Créatures',
  name: 'Créatures',
  nameSingular: 'Créature',
  isFeminine: true,
  tagId: TypesData[TYPE_CREATURE].tag,
  cardTemplate: TypesData[TYPE_CREATURE].datalines,
  images: {
    tile: '/assets/types/tile_maxi_creatures.jpg',
    banner: '/assets/types/page_maxi_creatures.jpg',
  },
});
entryTypes.set(TYPE_VEHICLE, {
  slug: 'vehicules',
  title: 'Véhicules',
  name: 'Véhicules',
  nameSingular: 'Véhicule',
  isFeminine: false,
  tagId: TypesData[TYPE_VEHICLE].tag,
  cardTemplate: TypesData[TYPE_VEHICLE].datalines,
  images: {
    tile: '/assets/types/tile_maxi_vehicules.jpg',
    banner: '/assets/types/page_maxi_vehicules.jpg',
  },
});
entryTypes.set(TYPE_OBJECT, {
  slug: 'objets',
  title: 'Objets',
  name: 'Objets',
  nameSingular: 'Objet',
  isFeminine: false,
  tagId: TypesData[TYPE_OBJECT].tag,
  cardTemplate: TypesData[TYPE_OBJECT].datalines,
  images: {
    tile: '/assets/types/tile_maxi_objets.jpg',
    banner: '/assets/types/page_maxi_objets.jpg',
  },
});
entryTypes.set(TYPE_TECHNOLOGY, {
  slug: 'technologies',
  title: 'Technologies',
  name: 'Technologies',
  nameSingular: 'Technologie',
  isFeminine: true,
  tagId: TypesData[TYPE_TECHNOLOGY].tag,
  cardTemplate: TypesData[TYPE_TECHNOLOGY].datalines,
  images: {
    tile: '/assets/types/tile_maxi_technologies.jpg',
    banner: '/assets/types/page_maxi_technologies.jpg',
  },
});
entryTypes.set(TYPE_SPACE, {
  slug: 'espaces',
  title: 'Zones Spatiales',
  name: 'Zones Spatiales',
  nameSingular: 'Zone Spatiale',
  isFeminine: true,
  tagId: TypesData[TYPE_SPACE].tag,
  cardTemplate: TypesData[TYPE_SPACE].datalines,
  images: {
    tile: '/assets/types/tile_maxi_espaces.jpg',
    banner: '/assets/types/page_maxi_espaces.jpg',
  },
});
entryTypes.set(TYPE_PLACE, {
  slug: 'lieux',
  title: 'Lieux',
  name: 'Lieux',
  nameSingular: 'Lieu',
  isFeminine: false,
  tagId: TypesData[TYPE_PLACE].tag,
  cardTemplate: TypesData[TYPE_PLACE].datalines,
  images: {
    tile: '/assets/types/tile_maxi_lieux.jpg',
    banner: '/assets/types/page_maxi_lieux.jpg',
  },
});
entryTypes.set(TYPE_PLANT, {
  slug: 'plantes',
  title: 'Minéraux & Végétaux',
  name: 'Minéraux & Végétaux',
  nameSingular: 'Minéral ou Végétal',
  isFeminine: false,
  tagId: TypesData[TYPE_PLANT].tag,
  cardTemplate: TypesData[TYPE_PLANT].datalines,
  images: {
    tile: '/assets/types/tile_maxi_plantes.jpg',
    banner: '/assets/types/page_maxi_plantes.jpg',
  },
});

export const isEntryType = (type: string) => {
  const identifiers = Array.from(entryTypes.keys());

  return identifiers.includes(type);
};
