function GameIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7577 3.91012C13.0038 3.39059 12.453 4.37496 9.99989 4.37496C7.54676 4.37496 6.99598 3.38668 5.24207 3.91012C3.48817 4.42965 2.61317 6.2695 1.78504 9.72262C0.956917 13.1797 1.10926 15.6757 2.08582 16.1406C3.06239 16.6054 4.16395 15.539 5.21473 14.2226C6.06629 13.1914 6.49207 13.125 9.99989 13.125C13.5038 13.125 13.8671 13.1523 14.785 14.2226C15.8358 15.539 16.9374 16.6054 17.9139 16.1406C18.8905 15.6757 19.0429 13.1797 18.2147 9.72262C17.3866 6.2695 16.5077 4.43356 14.7577 3.91012ZM5.0077 10.3007C4.16004 10.3007 3.47254 9.60934 3.47254 8.75387C3.47254 7.90231 4.16004 7.207 5.0077 7.207C5.85535 7.207 6.54285 7.90231 6.54285 8.75387C6.54285 9.60543 5.85535 10.3007 5.0077 10.3007ZM12.0975 9.51559C11.6835 9.51559 11.3436 9.17575 11.3436 8.75778C11.3436 8.33981 11.6835 7.99997 12.0975 7.99997C12.5155 7.99997 12.8554 8.33981 12.8554 8.75778C12.8514 9.17575 12.5155 9.51559 12.0975 9.51559ZM13.746 11.1718C13.3319 11.1718 12.9921 10.832 12.9921 10.414C12.9921 9.99215 13.3319 9.65621 13.746 9.65621C14.1639 9.65621 14.5038 9.99606 14.5038 10.414C14.5038 10.8359 14.1639 11.1718 13.746 11.1718ZM13.746 7.85543C13.3319 7.85543 12.9921 7.51559 12.9921 7.09762C12.9921 6.67965 13.3319 6.33981 13.746 6.33981C14.1639 6.33981 14.5038 6.67965 14.5038 7.09762C14.5038 7.51559 14.1639 7.85543 13.746 7.85543ZM15.3983 9.51559C14.9804 9.51559 14.6444 9.17575 14.6444 8.75778C14.6444 8.33981 14.9843 7.99997 15.3983 7.99997C15.8124 7.99997 16.1522 8.33981 16.1522 8.75778C16.1522 9.17575 15.8163 9.51559 15.3983 9.51559Z"
        fill="white"
      />
    </svg>
  );
}

export default GameIcon;
