import { useEffect } from 'react';

type ModifierOptions = {
  shift?: boolean;
  alt?: boolean;
  ctrl?: boolean;
};

/**
 * @param {string} key - the name of the key to respond to, compared against event.key
 */
export default function useKeypress(
  key: string,
  action: () => void,
  dependencies: Array<any>,
  modifiers: ModifierOptions = {},
) {
  useEffect(() => {
    function onKeyup(e) {
      if (e.key !== key) {
        return;
      }

      if (modifiers.shift && !e.shiftKey) {
        return;
      }

      if (modifiers.alt && !e.altKey) {
        return;
      }

      if (modifiers.ctrl && !e.ctrlKey && !e.metaKey) {
        return;
      }

      action();
    }
    window.addEventListener('keydown', onKeyup);
    return () => window.removeEventListener('keydown', onKeyup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
