function NovelIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.06189 8.87045V15.2409H11.8245H17.5871V8.87045V2.5H11.8245H6.06189V8.87045ZM12.0588 6.07487C12.1241 6.13054 12.2586 6.41814 12.516 7.05518L12.8848 7.95817L14.1141 7.96745L15.3473 7.97364L15.4511 8.06332C15.5586 8.153 15.5586 8.15918 15.5087 8.28597C15.4587 8.40967 15.3934 8.45606 14.5137 8.96941C13.995 9.27556 13.5494 9.53223 13.5225 9.54769C13.4841 9.56316 13.5609 9.78272 13.8414 10.4692C14.0411 10.964 14.2063 11.4155 14.2063 11.4712C14.2063 11.6784 13.9259 11.7866 13.7069 11.6629C13.6531 11.6351 13.2075 11.3753 12.7157 11.0908C12.1549 10.7599 11.8053 10.5775 11.7745 10.5929C11.7515 10.6053 11.3289 10.8465 10.8448 11.1279C9.90361 11.6753 9.83062 11.7155 9.73073 11.7155C9.62701 11.7155 9.4426 11.5454 9.4426 11.4495C9.4426 11.4032 9.61164 10.9578 9.81525 10.46C10.0189 9.96208 10.1802 9.54769 10.1725 9.54151C10.161 9.53532 9.70768 9.26937 9.166 8.95085C8.36692 8.48389 8.17484 8.36019 8.15563 8.28907C8.12874 8.19011 8.18252 8.05713 8.25935 8.01384C8.28625 8.00147 8.87019 7.98291 9.55401 7.97364L10.8026 7.95817L11.1906 7.02116C11.448 6.38721 11.6017 6.06869 11.6516 6.03776C11.7707 5.96664 11.9512 5.98519 12.0588 6.07487Z"
        fill="white"
      />
      <path
        d="M5.03611 2.63913C4.70188 2.77211 4.44833 2.98549 4.28313 3.2669L4.1602 3.47409L4.14867 9.60642L4.14099 15.7418L4.32155 15.6336C4.56358 15.4944 4.91702 15.3522 5.18978 15.2872L5.40876 15.2378V8.88279C5.40876 3.53594 5.40108 2.5309 5.35498 2.5309C5.32808 2.5309 5.1821 2.58037 5.03611 2.63913Z"
        fill="white"
      />
      <path
        d="M5.3828 15.7821C4.8949 15.8903 4.48 16.1532 4.27254 16.4933C4.16882 16.6665 4.16113 16.7036 4.16113 17.0345C4.16113 17.3747 4.16882 17.4025 4.28407 17.5942C4.44158 17.8509 4.74892 18.0859 5.09083 18.2189L5.35207 18.3179L11.4373 18.3271L17.5226 18.3333L17.4612 18.2653C16.835 17.554 16.7889 16.6881 17.3382 15.9429C17.4112 15.8439 17.4727 15.7573 17.4727 15.748C17.4727 15.7419 14.7989 15.7357 11.5296 15.7388C6.53916 15.7388 5.55184 15.748 5.3828 15.7821Z"
        fill="white"
      />
    </svg>
  );
}

export default NovelIcon;
