export function trimSentence(sentence: string, length: number, suffix = '…'): string {
  if (sentence.length <= length) {
    return sentence;
  }

  // Trim the string to the maximum length
  const trimmedString = sentence.substr(0, length);

  // Re-trim if we are in the middle of a word
  const result = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));

  return `${result}${suffix}`;
}

export function toStarWarsDate(value: string, showReference = true): string {
  let delimiterBaseValue = value;
  let circa = false;
  if (value.includes('~')) {
    delimiterBaseValue = value.slice(1);
    circa = true;
  }

  const isNumeric = parseInt(delimiterBaseValue, 10).toString() === delimiterBaseValue;
  if (isNumeric) {
    let number = '';
    let decimal = '';
    if (delimiterBaseValue.includes('.')) {
      const parts = delimiterBaseValue.split('.');
      delimiterBaseValue = parts[0];
      decimal = parts[1];
    }

    const iMax = delimiterBaseValue.length;
    for (let i = 0; i < iMax; i++) {
      if ((iMax - i) % 3 === 0 && number !== '-' && number !== '+') {
        number += ' ' + delimiterBaseValue[i];
      } else {
        number += delimiterBaseValue[i];
      }
    }

    delimiterBaseValue = number.trim() + (decimal !== '' ? '.' + decimal : '');

    // Skip calendar reference if specified
    if (!showReference) {
      return delimiterBaseValue === '0' ? 'An 0' : (circa ? '~' : '') + delimiterBaseValue;
    }
  } else if (!showReference) {
    return `${circa ? '~' : ''}${delimiterBaseValue}`;
  }

  const delimiter = parseFloat(delimiterBaseValue) >= 0 ? 'ap. BY' : 'av. BY';

  return `${circa ? '~' : ''}${delimiterBaseValue} ${delimiter}`;
}
