export const GtmFireEvent = function (category: string, action: string, label?: string, value?: string) {
  const event = {
    event: 'GAEvent',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
  };

  const win = window as any;
  if (!win || !win.dataLayer) {
    console.log('GTM not found (normal in dev mode). Event not fired.', event);
    return;
  }

  win.dataLayer.push(event);
};

export enum GtmCategory {
  CTAClick = 'cta_click',
  InfiniteScroll = 'infinite_scroll',
}

export enum GtmAction {
  UniverseSpotlight = 'universe_spotlight',
  CarouselSpotlight = 'carousel_spotlight',
  SingleEntrySpotlight = 'single_entry_spotlight',
  GridEntryList = 'grid_entry_list',
  GridSourceList = 'grid_source_list',
  EntryList = 'entry_list',
  RowUniveseByTypeList = 'row_universe_by_type_list',
  InPageSearchField = 'in_page_search_field',
  MenuSearchField = 'menu_search_field',
  KeyboardShortcutSearchField = 'keyboard_shortcut_search_field',
  MobileMainMenuItem = 'mobile_main_menu_item',
  DesktopMainMenuItem = 'desktop_main_menu_item',
  ArticleContentLink = 'article_content_link',
  ArticleCardLink = 'article_card_link',
}
