import { SearchType } from '@/types/model';
import { generateCancelToken } from 'api/fetch/apiFetch';
import { AggregationResult, doSearch, doSearchSource } from 'api/search';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

const minLength = 3;

function useInfiniteScroll(
  searchType: SearchType,
  query: string,
  pageNum: number,
  onScrollToBottom: () => void,
  filter?: AggregationResult,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isSearchable, setIsSearchable] = useState(false);

  const executeSearch = useCallback(
    (cancelSource, append) => {
      setError(false);

      const searchFunction = searchType === SearchType.Source ? doSearchSource : doSearch;
      searchFunction(query, filter, pageNum, cancelSource)
        .then((res) => {
          setItems((prev) => {
            if (res.results) {
              if (append === false) {
                return [...res.results];
              }
              return [...prev, ...res.results];
            }

            return prev;
          });
          setFilters(res.aggregations.filter((agg) => agg.field === 'type') || []);
          setHasMore(res.hasNext || false);
          setIsLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) return;
          setError(err);
        });
    },
    [filter, pageNum, query, searchType],
  );

  const debouncedExecuteSearch = debounce(executeSearch, 500);

  // When query changes
  useEffect(() => {
    if (query.length < minLength) {
      setIsSearchable(false);
      setIsLoading(false);
      setFilters([]);
      return;
    }

    setIsSearchable(true);
    setIsLoading(true);
    setItems([]);

    const cancelSource = generateCancelToken();

    // Search with debounce and do not append
    debouncedExecuteSearch(cancelSource, false);

    return () => cancelSource.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // When filter changes
  useEffect(() => {
    if (query.length < minLength) {
      setIsSearchable(false);
      setIsLoading(false);
      setFilters([]);
      return;
    }

    setIsSearchable(true);
    setIsLoading(true);
    setItems([]);

    const cancelSource = generateCancelToken();

    // Search and get rid of previous results
    executeSearch(cancelSource, false);
    return () => cancelSource.cancel();
  }, [filter, executeSearch, query.length]);

  // When page change
  useEffect(() => {
    if (query.length < minLength) {
      setIsSearchable(false);
      setIsLoading(false);
      setFilters([]);
      return;
    }

    setIsSearchable(true);
    setIsLoading(true);

    const cancelSource = generateCancelToken();

    // Search and append results
    executeSearch(cancelSource, true);

    return () => cancelSource.cancel();
  }, [pageNum, executeSearch, query.length]);

  const observer: any = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          onScrollToBottom();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore, onScrollToBottom],
  );

  return {
    isLoading,
    error,
    items,
    filters,
    hasMore,
    isSearchable,
    lastItemRef,
  };
}

export default useInfiniteScroll;
