import { getConfigValue } from '@/utils/config';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

const apiHost = getConfigValue('apiHost');

const apiFetch = async (path: string, source?: CancelTokenSource): Promise<any | any[]> => {
  try {
    const config: AxiosRequestConfig = {};
    if (source) {
      config.cancelToken = source.token;
    }

    const res = await axios.get(`${apiHost}${path}`, config);

    return res.data;
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }

  return [];
};

export const generateCancelToken = () => {
  const cancelToken = axios.CancelToken;

  return cancelToken.source();
};

export default apiFetch;
