import { ReactNode } from 'react';
import classes from './SectionTitle.module.css';

type Props = {
  children: ReactNode;
};

function SectionTitle(props: Props) {
  return <h1 className={classes.title}>{props.children}</h1>;
}

export default SectionTitle;
