function LeftArrowIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8417 6.175L11.6667 5L6.66669 10L11.6667 15L12.8417 13.825L9.02502 10L12.8417 6.175Z"
        fill="#333333"
      />
    </svg>
  );
}

export default LeftArrowIcon;
