export type SourceType = {
  slug: string;
  title: string; // Usable as standalone title
  name: string; // For usage in sentenres like "Tous les ..."
  nameSingular: string; // Singular of name
  isFeminine: boolean; // If name is feminine or not
  releaseVerb: string; // Verb used for sentences like "XXX sorti en 2021",  "XXX publié en 2021"
  images: {
    banner: string;
    tile: string;
  };
};

export enum SourceTypes {
  TYPE_MOVIE = 'movie',
  TYPE_TVSHOW = 'tvshow_serie',
  TYPE_TVSHOW_SEASON = 'season',
  TYPE_COMIC = 'comic',
  TYPE_COMIC_SERIE = 'comic_serie',
  TYPE_NOVEL = 'novel',
  TYPE_GAME = 'game',
  TYPE_SOURCEBOOK = 'sourcebook',
}

export type SourceSerieType = SourceTypes.TYPE_TVSHOW | SourceTypes.TYPE_COMIC_SERIE;

export const TYPE_MOVIE = SourceTypes.TYPE_MOVIE;
export const TYPE_TVSHOW = SourceTypes.TYPE_TVSHOW;
export const TYPE_TVSHOW_SEASON = SourceTypes.TYPE_TVSHOW_SEASON;
export const TYPE_COMIC = SourceTypes.TYPE_COMIC;
export const TYPE_COMIC_SERIE = SourceTypes.TYPE_COMIC_SERIE;
export const TYPE_NOVEL = SourceTypes.TYPE_NOVEL;
export const TYPE_GAME = SourceTypes.TYPE_GAME;
export const TYPE_SOURCEBOOK = SourceTypes.TYPE_SOURCEBOOK;
export const SEARCHABLE_TYPES = [TYPE_MOVIE, TYPE_TVSHOW_SEASON, TYPE_COMIC, TYPE_NOVEL, TYPE_GAME, TYPE_SOURCEBOOK];

export const sourceTypes: Map<string, SourceType> = new Map();
sourceTypes.set(TYPE_MOVIE, {
  slug: 'films',
  title: 'Films',
  name: 'Films',
  nameSingular: 'Film',
  isFeminine: false,
  releaseVerb: 'sorti',
  images: {
    banner: '/assets/sources/page_maxi_movies.png',
    tile: '/assets/sources/tile_maxi_movies.png',
  },
});
sourceTypes.set(TYPE_TVSHOW_SEASON, {
  slug: 'series-tv',
  title: 'Séries TV',
  name: 'Saisons',
  nameSingular: 'Saison',
  isFeminine: true,
  releaseVerb: 'diffusée',
  images: {
    banner: '/assets/sources/page_maxi_tvshows.png',
    tile: '/assets/sources/tile_maxi_tvshow.png',
  },
});
sourceTypes.set(TYPE_TVSHOW, {
  slug: 'series-tv',
  title: 'Séries TV',
  name: 'Séries TV',
  nameSingular: 'Série TV',
  isFeminine: true,
  releaseVerb: 'diffusée',
  images: {
    banner: '/assets/sources/page_maxi_tvshows.png',
    tile: '/assets/sources/tile_maxi_tvshow.png',
  },
});
sourceTypes.set(TYPE_COMIC, {
  slug: 'bandes-dessinees',
  title: 'Bandes dessinées',
  name: 'Bandes dessinées',
  nameSingular: 'Bande dessinée',
  isFeminine: true,
  releaseVerb: 'publiée',
  images: {
    banner: '/assets/sources/page_maxi_comics.png',
    tile: '/assets/sources/tile_maxi_comics.png',
  },
});
sourceTypes.set(TYPE_COMIC_SERIE, {
  slug: 'bandes-dessinees',
  title: 'Bandes dessinées',
  name: 'Séries de BD',
  nameSingular: 'Série de BD',
  isFeminine: true,
  releaseVerb: 'publiée',
  images: {
    banner: '/assets/sources/page_maxi_comics.png',
    tile: '/assets/sources/tile_maxi_comics.png',
  },
});
sourceTypes.set(TYPE_NOVEL, {
  slug: 'romans',
  title: 'Romans',
  name: 'Romans',
  nameSingular: 'Roman',
  isFeminine: false,
  releaseVerb: 'publié',
  images: {
    banner: '/assets/sources/page_maxi_novels.png',
    tile: '/assets/sources/tile_maxi_novels.jpg',
  },
});
sourceTypes.set(TYPE_GAME, {
  slug: 'jeux-videos',
  title: 'Jeux Vidéo',
  name: 'Jeux Vidéo',
  nameSingular: 'Jeu Vidéo',
  isFeminine: false,
  releaseVerb: 'sorti',
  images: {
    banner: '/assets/sources/page_maxi_games.png',
    tile: '/assets/sources/tile_maxi_games.png',
  },
});
sourceTypes.set(TYPE_SOURCEBOOK, {
  slug: 'sourcebooks',
  title: 'Beaux Livres',
  name: 'Beaux Livres',
  nameSingular: 'Beau livre',
  isFeminine: false,
  releaseVerb: 'sorti',
  images: {
    banner: '/assets/sources/page_maxi_games.png',
    tile: '/assets/sources/tile_maxi_games.png',
  },
});

export const isSourceType = (type: string) => {
  const identifiers = Array.from(sourceTypes.keys());

  return identifiers.includes(type);
};
