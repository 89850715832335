import { Picture, UrlDefinition } from '@/types/model';
import { SourceTypes } from '@/utils/references/sourceTypes';

export enum HighlightType {
  MENU = 'menu',
  CAROUSEL = 'carousel',
  UNIVERSE = 'universe',
}

export interface HighlightDocument {
  type: HighlightType;
  position: number;
  name: string;
  config: HighlightConfig;
}

export interface MenuHighlightConfig {
  label: string;
  routeConfig: UrlDefinition;
}
export interface CarouselHighlightConfig {
  label: string;
  routeConfig: UrlDefinition;
  image: Picture;
  logo?: Picture; // Logo of the source
}
export interface UniverseHighlightConfig {
  label: string;
  routeConfig: UrlDefinition;
  image: Picture; // Background image
  logo?: Picture; // Logo of the source
  itemsSourceType: SourceTypes; // Type of source to fetch ordered entries from
  itemsSourceId: number; // Id of source to fetch ordered entries from
}

export type HighlightConfig = MenuHighlightConfig & CarouselHighlightConfig & UniverseHighlightConfig;
