import {
  CalculatedVersions,
  HighlightConfig,
  HighlightType,
  PictureSize,
  SourceDocument,
  Universe,
  VersionDocument,
} from '@/types/documents';

export enum PictureFormat {
  Square = 'square',
  Ratio_16_9 = 'landscape',
  Page = 'page',
  Tile = 'tile',
  Original = 'original',
}

export enum PictureRatio {
  Ratio_1_1 = 'ratio-1-1',
  Ratio_2_3 = 'ratio-2-3',
  Ratio_4_3 = 'ratio-4-3',
  Ratio_Free = 'ratio-free',
}

export type Picture = {
  id: number;
  urls: {
    square?: string;
    page?: string;
    original?: string;
    tile?: string;
  };
  sizes: {
    square?: PictureSize;
    page?: PictureSize;
    original?: PictureSize;
    tile?: PictureSize;
  };
  lastUpdateTimestamp?: string;
};

export type SourceListItem = {
  name: string;
  slug: string;
  type: {
    name: string;
    slug: string;
  };
  serie?: {
    slug: string;
    name: string;
  };
  logo?: Picture;
  image?: Picture;
  firstReleasedAt?: string;
  releasePeriod?: string;
  description?: string;
};

export type CardSection = {
  title?: string;
  items: Array<CardItem>;
};

export type CardItem = {
  label: string;
  values: Array<CardItemValue>;
};

export type CardItemValue = {
  text: string;
  slug?: string;
  href?: string;
};

export type Tag = {
  name: string;
  slug: string;
};

export type EntryListItem = {
  name: string;
  slug: string;
  type: {
    name: string;
    slug: string;
  };
  description: string;
  version?: string;
  calculatedVersions: CalculatedVersions;
  image?: Picture;
};

export type TileItem = {
  name: string;
  key: string;
  url: string;
  subtitle: {
    elementCount: number;
    elementLabel: string;
  };
  image: Picture;
};

export interface DatedItem {
  name: string;
  url: string;
  date: string | null;
  eraId?: number;
  description?: string;
  image?: Picture;
}

export type Pager<T> = {
  results: Array<T>;
  count: number;
  links: {
    next?: string;
  };
};

export type RouteTypeVersion = RouteType.RouteTypeVersion;
export type RouteTypeHref = RouteType.RouteTypeHref;
export type RouteTypeSourceMovie = RouteType.RouteTypeSourceMovie;
export type RouteTypeSourceTvShowSerie = RouteType.RouteTypeSourceTvShowSerie;
export type RouteTypeSourceTvShowSeason = RouteType.RouteTypeSourceTvShowSeason;
export type RouteTypeSourceComic = RouteType.RouteTypeSourceComic;
export type RouteTypeSourceComicSerie = RouteType.RouteTypeSourceComicSerie;
export type RouteTypeSourceNovel = RouteType.RouteTypeSourceNovel;
export type RouteTypeSourceGame = RouteType.RouteTypeSourceGame;

export enum RouteType {
  RouteTypeSourceMovie = 'movie',
  RouteTypeSourceTvShowSerie = 'tvshow_serie',
  RouteTypeSourceTvShowSeason = 'season',
  RouteTypeSourceComic = 'comic',
  RouteTypeSourceComicSerie = 'comic_serie',
  RouteTypeSourceNovel = 'novel',
  RouteTypeSourceGame = 'game',
  RouteTypeVersion = 'version',
  RouteTypeHref = 'href',
}

export interface UrlDefinition {
  routeType: RouteType;
  objectId: number;
  universe?: Universe;
  resolvedTarget?: string & SourceListItem & EntryListItem;
  resolvedDocument?: VersionDocument & SourceDocument;
}

export interface HighlightItem {
  type: HighlightType;
  config: HighlightConfig;
}

export enum SearchType {
  Entry = 'entry',
  Source = 'source',
}

export enum TabTemplate {
  Center = 'center',
  Left = 'left',
}

export enum ListTemplate {
  Grid = 'grid',
  Row = 'row',
}

export interface BreadcrumbItem {
  title: string;
  url?: string;
}
