import { SearchBlockProps } from '@/builders/blocks/renderers';
import Bb8Icon from '@/components/icons/Bb8Icon';
import LoaderIcon from '@/components/icons/LoaderIcon';
import VerticalEntryList from '@/components/ui/Block/List/VerticalEntryList';
import VerticalSourceList from '@/components/ui/Block/List/VerticalSourceList';
import FullViewportContainer from '@/components/ui/Layout/FullViewportContainer';
import SearchField from '@/components/ui/Search/SearchField';
import SearchFilters from '@/components/ui/Search/SearchFilters';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { SearchType } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import { AggregationResult } from 'api/search';
import { ChangeEvent, ReactNode, useRef, useState } from 'react';
import classes from './Search.module.css';

function Search(props: SearchBlockProps) {
  const { terms, placeholder, filter, alwaysFullViewport, onFullViewportClose, searchType, context } = props;
  const [value, setValue] = useState(terms || '');
  const [currentFilter, setCurrentFilter] = useState(filter);
  const [pageNum, setPageNum] = useState(1);
  const [isFullViewport, setFullViewport] = useState(alwaysFullViewport);
  const openFullViewport = alwaysFullViewport || isFullViewport;
  const inputRef = useRef(null);

  const onScrollToBottom = () => setPageNum((prev) => prev + 1);

  const { isLoading, items, filters, isSearchable, lastItemRef } = useInfiniteScroll(
    searchType,
    value,
    pageNum,
    onScrollToBottom,
    currentFilter,
  );

  // Open search when hitting cmd/ctrl+k key
  /*useKeypress(
    'k',
    () => {
      if (!isFullViewport) {
        setFullViewport(true);
        inputRef.current && inputRef.current.focus();
      }
    },
    [isFullViewport],
    { ctrl: true },
  );*/

  const handleSearchFieldFocus = () => {
    if (!context || context === 'inpage') {
      GtmFireEvent(GtmCategory.CTAClick, GtmAction.InPageSearchField);
    }

    setFullViewport(true);
  };

  const handleCloseFullViewport = () => {
    setFullViewport(false);

    onFullViewportClose && onFullViewportClose();
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setValue(inputValue);
    setPageNum(1);
  };

  const handleFilterSelect = (filter: AggregationResult) => {
    setCurrentFilter(filter);
    setPageNum(1);
  };

  const resetField = () => {
    setValue('');
    setPageNum(1);

    inputRef.current && inputRef.current.focus();
  };

  const resetFilter = () => {
    setCurrentFilter(null);
    setPageNum(1);
  };

  const doSearchNode = (
    <div className={classes.doSearch}>
      <Bb8Icon />
      <p>Ouvrez cette recherche rapidement et depuis n&apos;importe quelle page du site avec les raccourcis</p>
      <p>
        <span className={classes.shortcut}>Ctrl+K</span> ou <span className={classes.shortcut}>Cmd+K</span>
      </p>
    </div>
  );

  let itemsNode: ReactNode;
  let noItemsNode: ReactNode;
  if (items.length === 0) {
    noItemsNode = (
      <div className={classes.noResults}>
        <Bb8Icon />
        <p>Aucun résultat pour cette recherche !</p>
      </div>
    );
  } else {
    itemsNode =
      searchType === SearchType.Source ? (
        <VerticalSourceList onItemClick={() => handleCloseFullViewport()} items={items} lastItemRef={lastItemRef} />
      ) : (
        <VerticalEntryList onItemClick={() => handleCloseFullViewport()} items={items} lastItemRef={lastItemRef} />
      );
  }

  return (
    <FullViewportContainer
      title={searchType === SearchType.Entry ? 'Rechercher un article' : 'Rechercher une publication'}
      isFullViewport={openFullViewport}
      onClose={handleCloseFullViewport}
      anchorPath="search"
    >
      <SearchField
        terms={value}
        placeholder={placeholder}
        filter={currentFilter}
        inputChangeHandler={handleSearchChange}
        inputResetHandler={resetField}
        filterResetHandler={resetFilter}
        inputClickHandler={handleSearchFieldFocus}
        autoFocus={alwaysFullViewport}
        inputRef={inputRef}
      />
      {openFullViewport && filters.length > 0 && (
        <SearchFilters filters={filters} onFilterSelect={handleFilterSelect} />
      )}
      {openFullViewport && isSearchable && (isLoading || items.length > 0) && itemsNode}
      {openFullViewport && isSearchable && !isLoading && items.length === 0 && noItemsNode}
      {openFullViewport && isLoading && (
        <div className={classes.loader}>
          <LoaderIcon />
        </div>
      )}
      {openFullViewport && !isLoading && !isSearchable && doSearchNode}
    </FullViewportContainer>
  );
}

export default Search;
