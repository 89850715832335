import { EntryListItem, HighlightItem, SourceListItem } from '@/types/model';
import { CancelTokenSource } from 'axios';
import fetcher from './fetch/apiFetch';

export type AggregationResult = {
  field: string;
  value: string;
  label: string;
  count?: number;
};

export type SearchResponse<T> = {
  results: Array<T>;
  aggregations: Array<AggregationResult>;
  hasNext: boolean;
};

export const doSearch = async (
  terms: string,
  filter?: AggregationResult,
  page?: number,
  source?: CancelTokenSource,
): Promise<SearchResponse<EntryListItem>> => {
  return fetcher(
    `/content/entry/search?terms=${terms}&page=${page ? page : 1}${filter ? `&type=${filter.value}` : ''}`,
    source,
  );
};

export const doSearchSource = async (
  terms: string,
  filter?: AggregationResult,
  page?: number,
  source?: CancelTokenSource,
): Promise<SearchResponse<SourceListItem>> => {
  return fetcher(
    `/content/source/search?terms=${terms}&page=${page ? page : 1}${filter ? `&type=${filter.value}` : ''}`,
    source,
  );
};

export const doSearchHighlight = async (source?: CancelTokenSource): Promise<SearchResponse<HighlightItem>> => {
  return fetcher(`/content/highlight/search`, source);
};
