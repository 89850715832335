import CloseIcon from '@/components/icons/CloseIcon';
import useKeypress from '@/hooks/useKeypress';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect } from 'react';
import classes from './FullViewportContainer.module.css';

type Props = {
  isFullViewport: boolean;
  title: string;
  children: ReactNode;
  anchorPath: string;
  onClose: () => void;
  onEnteredFullViewport?: () => void;
};

function FullViewportContainer(props: Props) {
  const { title, isFullViewport, children, onClose, onEnteredFullViewport, anchorPath } = props;
  const router = useRouter();

  const exitFullViewport = useCallback(() => {
    onClose();
  }, [onClose]);

  // Close search when esc key is pressed
  useKeypress(
    'Escape',
    () => {
      if (isFullViewport) {
        exitFullViewport();
      }
    },
    [isFullViewport],
  );

  // Close search when browser back button pressed (also prevent back navigation)
  useEffect(() => {
    const preventNavigationAndClose = () => {
      exitFullViewport();
      router.events.emit('routeChangeError');

      // Following is a hack-ish solution to abort a Next.js route change
      // as there's currently no official API to do so
      // See https://github.com/zeit/next.js/issues/2476#issuecomment-573460710
      // eslint-disable-next-line no-throw-literal
      throw `Route change was aborted.`;
    };

    const clearHashNavigationHack = () => {
      router.events.off('hashChangeStart', preventNavigationAndClose);
    };

    const enterFullViewport = () => {
      const virtualPath = `#${anchorPath}`;
      if (window.location.hash !== virtualPath) {
        router.push(`${window.location}${virtualPath}`).then(() => {
          router.events.on('routeChangeStart', clearHashNavigationHack);
          router.events.on('hashChangeStart', preventNavigationAndClose);
        });
      }

      onEnteredFullViewport && onEnteredFullViewport();
    };

    if (isFullViewport) {
      enterFullViewport();
    } else {
      router.events.off('hashChangeStart', preventNavigationAndClose);
      router.events.off('routeChangeStart', clearHashNavigationHack);
    }

    return () => {
      router.events.off('hashChangeStart', preventNavigationAndClose);
      router.events.off('routeChangeStart', clearHashNavigationHack);
    };
  }, [isFullViewport, router, onEnteredFullViewport, anchorPath, exitFullViewport]);

  return (
    <div className={isFullViewport ? classes.containerFixed : classes.container}>
      {isFullViewport && (
        <div className={classes.header}>
          <h2 className={classes.title}>{title}</h2>
          <a onClick={() => exitFullViewport()} className={classes.closeButton}>
            <CloseIcon />
          </a>
        </div>
      )}
      <section className={classes.content}>{children}</section>
    </div>
  );
}

export default FullViewportContainer;
