function ChronologyIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.98867 2.55477C7.81543 2.63008 6.1281 4.29717 6.07959 4.44095C6.05534 4.5231 6.05534 4.58472 6.07959 4.66688C6.12463 4.79696 7.80158 6.46405 7.98867 6.56332C8.10301 6.62152 8.26239 6.62494 11.2663 6.62494C14.7311 6.62494 14.5509 6.63521 14.6583 6.41613C14.7068 6.31685 14.7103 6.08065 14.7034 4.50257L14.693 2.69855L14.5786 2.59927L14.4678 2.5H11.2871C8.52917 2.50343 8.09262 2.51027 7.98867 2.55477Z"
        fill="white"
      />
      <path
        d="M3.38053 3.14014V3.37292L3.18997 3.47219C2.43119 3.86244 2.30646 4.89281 2.9509 5.47476C3.05485 5.56718 3.19344 5.66303 3.25927 5.68699L3.38053 5.73149V7.46363V9.19576L3.25927 9.24026C3.08256 9.30188 2.7742 9.59969 2.67372 9.80166C2.62522 9.90093 2.56631 10.0687 2.54899 10.1748C2.45198 10.6848 2.74302 11.2462 3.22462 11.4756L3.38053 11.5509V13.2728V14.9946L3.21423 15.0768C3.02366 15.1726 2.76034 15.4362 2.65986 15.6348C2.35496 16.2236 2.62175 17.0006 3.23155 17.2916L3.38053 17.3635V17.5997V17.8359H3.7963H4.21208V17.5997V17.3635L4.39571 17.2608C4.62438 17.1341 4.81841 16.9253 4.93621 16.6754C5.01244 16.5145 5.0263 16.4358 5.0263 16.1756C5.0263 15.912 5.01244 15.8402 4.93275 15.6724C4.82187 15.4396 4.56548 15.1726 4.36106 15.0802L4.21554 15.0117L4.21208 13.2796V11.5509L4.33334 11.4927C4.48926 11.4208 4.75258 11.1812 4.85652 11.0203C5.06787 10.6848 5.09559 10.209 4.92235 9.84274C4.81148 9.61339 4.55855 9.35322 4.3576 9.26422L4.21208 9.19918V7.46363V5.72807L4.3576 5.66303C4.56202 5.57061 4.80802 5.32414 4.92235 5.08451C5.00897 4.90651 5.02283 4.83804 5.0263 4.57104C5.0263 4.3006 5.01244 4.23556 4.92582 4.05071C4.81494 3.81109 4.5343 3.52012 4.33334 3.43796L4.21208 3.39004V3.15041V2.91079H3.7963H3.38053V3.14014Z"
        fill="white"
      />
      <path
        d="M8.14454 8.31601C7.94359 8.36393 7.86043 8.43582 6.99078 9.29162C6.06569 10.2022 6.01025 10.2741 6.07955 10.4863C6.13152 10.6404 7.81539 12.3006 7.99556 12.3725C8.1099 12.4204 8.6504 12.4273 12.7631 12.4273C17.3227 12.4273 17.4058 12.4273 17.489 12.3588C17.6553 12.2321 17.6588 12.215 17.6484 10.3151C17.638 8.58644 17.6345 8.52825 17.5687 8.45636C17.4093 8.27835 17.6068 8.2852 12.7631 8.28862C10.2788 8.29205 8.20344 8.30232 8.14454 8.31601Z"
        fill="white"
      />
      <path
        d="M8.05812 14.1491C7.89182 14.197 6.09707 15.9531 6.06588 16.0969C6.02084 16.2989 6.0936 16.3947 6.97712 17.2608C7.59038 17.8667 7.87796 18.1268 7.98883 18.1748C8.14128 18.2432 8.20364 18.2467 11.0655 18.2467C13.6503 18.2467 13.9967 18.2398 14.0903 18.1919C14.2947 18.0892 14.2947 18.0755 14.2947 16.1722C14.2947 14.666 14.2878 14.4264 14.2393 14.3271C14.1284 14.0943 14.2704 14.1046 11.0655 14.108C9.1322 14.1114 8.13781 14.1251 8.05812 14.1491Z"
        fill="white"
      />
    </svg>
  );
}

export default ChronologyIcon;
