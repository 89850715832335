import { GalleryContext } from 'context/GalleryContext';
import { Fragment, useContext } from 'react';
import ReactSimpleImageViewer from 'react-simple-image-viewer';

function FullscreenGallery() {
  const galleryContext = useContext(GalleryContext);

  const closeGallery = () => {
    galleryContext.updateState({ ...galleryContext, isOpened: false, pictureUrls: [], currentIndex: undefined });
  };

  return (
    <Fragment>
      {galleryContext.isOpened && (
        <ReactSimpleImageViewer
          src={galleryContext.pictureUrls}
          currentIndex={galleryContext.currentIndex}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeGallery}
        />
      )}
    </Fragment>
  );
}

export default FullscreenGallery;
