import { PictureStructure } from '@/types/documents';
import { Tag } from '@/types/model';

type Affiliation = {
  id: number;
  name: string;
};

export enum CalculatedVersions {
  Legends = 1,
  Canon = 2,
  All = 3,
}

export enum Universe {
  Legends = 'legends',
  Canon = 'canon',
}

enum CardLineValueOrigin {
  Relation = 'relation',
  Identity = 'identity',
}

interface CardSection {
  section: string;
  lines: Array<CardLine>;
}

interface CardLine {
  id: number;
  name: string;
  data: Array<CardLineValue>;
}

interface CardLineValue {
  value: string | { type: string; slug: string; name: string };
  from: CardLineValueOrigin;
}

interface EraPosition {
  era: number;
  position: number;
  date: string | null;
  historicContextEntryId: number | null;
}

interface Alias {
  name: string;
  isVisible: boolean;
}

export type VersionDocument = {
  name: string;
  slug: string;
  description: string;
  universe?: Universe;
  author: string;
  content?: string;
  type: string;
  typeAsSlug: string;
  mainPicture?: PictureStructure;
  coverPicture?: PictureStructure;
  contentPictures: Array<PictureStructure>;
  rewrittenAt: string;
  affiliations: Array<Affiliation>;
  entryId: number;
  isWritten: boolean;
  calculatedVersions: CalculatedVersions;
  card: Array<CardSection>;
  contextualEntries: Array<number>;
  chronology?: EraPosition;
  entry: {
    aliases: Array<Alias>;
  };
  tags: Array<Tag>;
  references: Array<number>;
};
