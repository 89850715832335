import CloseIcon from '@/components/icons/CloseIcon';
import Footer from '@/components/ui/Layout/Footer';
import Header from '@/components/ui/Layout/Header/Header';
import MobileMenu from '@/components/ui/Layout/Header/Mobile/MobileMenu';
import FullscreenGallery from '@/components/ui/Picture/FullscreenGallery';
import useIsUnderMediaQuery from '@/hooks/useMediaQuery';
import useMenu from '@/hooks/useMenu';
import classNames from 'classnames';
import { GalleryProvider } from 'context/GalleryContext';
import Head from 'next/head';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { slide as BurgerMenu } from 'react-burger-menu';
import CookieConsent from 'react-cookie-consent';
import classes from './Layout.module.css';

type Props = {
  children: ReactNode;
  useTransparentHeader?: boolean;
  mainTag?: 'article' | 'main';
};

function Layout(props: Props) {
  const [isScrolledToTop, setIsScrolledToTop] = useState<boolean>(true);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const { useTransparentHeader, children, mainTag } = props;
  const menu = useMenu();

  const isMobile = useIsUnderMediaQuery(768);

  const toggleMenu = () => {
    setIsMenuOpened(true);
  };

  const topObservedElement: any = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      setIsScrolledToTop(entry.isIntersecting);
    });

    if (topObservedElement.current) {
      observer.observe(topObservedElement.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [topObservedElement]);

  const mainClasses = classNames(classes.content, {
    [`${classes.withHeaderSizeMargin}`]: isMobile && !useTransparentHeader,
  });

  return (
    <div
      className={classNames(classes.container, {
        notTop: !isScrolledToTop,
        plainHeader: !useTransparentHeader,
      })}
    >
      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <meta name="viewport" content="width=device-width" />
        <link rel="manifest" href="/manifest.webmanifest" />
        <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
        <meta name="theme-color" content="#0a0b0b" />
        <title>ANAKINWORLD : L&apos;Encyclopédie Star Wars</title>
        <meta
          name="description"
          content="L'Encyclopédie Star Wars : Plus de 11000 références ! Une Encyclopédie pour un nouveau regard sur l'Univers Etendu !"
        />
      </Head>
      <div ref={topObservedElement}></div>
      <Header isMobile={isMobile} menu={menu} toggleMenu={toggleMenu} />
      {isMobile && (
        <BurgerMenu
          isOpen={isMenuOpened}
          customBurgerIcon={false}
          customCrossIcon={<CloseIcon />}
          onStateChange={(state) => setIsMenuOpened(state.isOpen)}
        >
          <MobileMenu items={menu} />
        </BurgerMenu>
      )}
      <GalleryProvider>
        {(mainTag === 'main' || typeof mainTag === 'undefined') && <main className={mainClasses}>{children}</main>}
        {mainTag === 'article' && <article className={mainClasses}>{children}</article>}
        <FullscreenGallery />
      </GalleryProvider>
      <CookieConsent
        location="bottom"
        disableStyles={true}
        buttonText="Accepter"
        cookieName="awCookieConsent"
        expires={150}
      >
        Ce site utilise des cookies afin d&apos;améliorer l&apos;expérience utilisateur.
      </CookieConsent>
      <Footer />
    </div>
  );
}

export default Layout;
