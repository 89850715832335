import Link from 'next/link';
import { ReactNode } from 'react';
import classes from './Button.module.css';

type Props = {
  children: ReactNode;
  href: string | object;
  primary?: boolean;
  alternate?: boolean;
  faded?: boolean;
  onClickHandler?: () => void;
};

function Button(props: Props) {
  const style = [classes.button];

  if (props.primary) {
    style.push(classes.primary);
  }
  if (props.alternate) {
    style.push(classes.alternate);
  }
  if (props.faded) {
    style.push(classes.faded);
  }

  return (
    <Link
      href={props.href}
      scroll={true}
      className={style.join(' ')}
      onClick={props.onClickHandler ? props.onClickHandler : null}
    >
      <span className={classes.content}>{props.children}</span>
    </Link>
  );
}

export default Button;
