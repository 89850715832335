function ComicsIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.20085 2.02724C7.15838 2.05059 7.08888 2.11285 7.04255 2.16343L6.96533 2.26071L7.10819 3.00779C7.18927 3.42024 7.3939 4.41246 7.56765 5.21401C7.73753 6.01557 8.15066 7.92608 8.47884 9.45526C9.47112 14.0662 9.47498 14.0817 9.62556 14.2179C9.69892 14.2802 9.75297 14.2879 9.98463 14.2646C10.2742 14.2413 11.5329 13.9922 12.1815 13.8288C12.7259 13.6926 12.7066 13.7237 12.6835 12.8599C12.6719 12.4747 12.6449 11.4163 12.6217 10.5058C12.5676 8.16343 12.4364 3.8716 12.4093 3.37744C12.3862 3.01946 12.3707 2.94942 12.3012 2.88328C12.2356 2.82102 11.9885 2.76654 11.0812 2.60701C10.1391 2.44359 7.31668 1.98055 7.28579 1.98444C7.28193 1.98444 7.24332 2.0039 7.20085 2.02724Z"
        fill="white"
      />
      <path
        d="M5.80696 4.28015C5.45561 4.37354 4.73747 4.66926 4.36295 4.87159C2.77994 5.74319 1.70658 7.12451 1.44789 8.63035C1.17762 10.2101 1.56758 11.6732 2.64094 13.1051C2.91507 13.4669 3.61005 14.1829 4.12743 14.6303L4.37453 14.8482L4.3282 14.9805C4.11198 15.607 3.27029 16.4669 2.45175 16.8949C1.60233 17.3346 1.50967 17.4241 1.58303 17.7043C1.60619 17.786 1.6448 17.8716 1.67569 17.8949C1.87646 18.0661 3.50967 18.2179 4.26642 18.1401C5.68341 17.9883 6.81855 17.5058 7.55986 16.7354L7.84171 16.4436L8.18148 16.5097C8.67569 16.607 8.82627 16.5992 8.9421 16.4708C8.99229 16.4163 9.04635 16.323 9.06179 16.2724C9.09654 16.1362 8.96527 15.9144 8.81469 15.8521C8.60619 15.7626 7.67183 15.5875 7.55986 15.6148C7.50195 15.6303 7.34751 15.7704 7.17762 15.9611C6.83785 16.358 6.57917 16.5564 6.1004 16.8054C5.44789 17.144 4.89577 17.2879 4.00002 17.358C3.19693 17.4202 3.20465 17.4241 3.59075 17.1051C4.60233 16.2685 5.17376 15.3891 5.17376 14.6615C5.17376 14.4514 5.1004 14.3619 4.5753 13.9416C3.99615 13.4747 3.77222 13.2568 3.417 12.8132C2.55986 11.7315 2.16218 10.7004 2.16218 9.54864C2.16218 8.61478 2.42859 7.8249 3.00002 7.05058C3.61778 6.21012 4.55986 5.57587 5.88805 5.09338C6.52125 4.86381 6.57917 4.81323 6.55214 4.53696C6.52125 4.19844 6.34364 4.13619 5.80696 4.28015Z"
        fill="white"
      />
      <path
        d="M13.0733 4.94552C13.0038 5.00778 12.9613 5.08949 12.9459 5.19844C12.9189 5.41245 13.0501 5.5642 13.4517 5.76653C14.2509 6.1712 14.9343 6.67704 15.6216 7.36965C16.4285 8.19066 16.942 9.04669 17.193 9.99221C17.305 10.4163 17.3127 10.4903 17.3088 11.1089C17.3088 11.7004 17.2972 11.8132 17.2084 12.1595C17.0386 12.7899 16.7837 13.2763 16.3166 13.8444C15.7606 14.5214 15.0965 14.9844 13.9266 15.5019C13.7451 15.5798 13.6911 15.6265 13.6447 15.7393C13.5405 15.9922 13.6949 16.2646 13.9459 16.2646C14.1737 16.2646 14.9266 15.9377 15.5019 15.5875C16.4942 14.9844 17.3667 13.9728 17.7799 12.9377C18.2857 11.677 18.2316 10.214 17.6332 8.85992C17.4864 8.53307 17.1467 7.96109 16.8841 7.607C16.5907 7.21011 15.9034 6.48638 15.525 6.17898C14.8455 5.62646 13.6563 4.91828 13.3281 4.87548C13.2084 4.85992 13.1583 4.87548 13.0733 4.94552Z"
        fill="white"
      />
      <path
        d="M11.9885 15.0856C9.91898 15.2101 9.80701 15.2374 9.80701 15.607C9.80701 15.8327 9.96917 16.8677 10.0811 17.3619C10.1391 17.607 10.1892 17.7276 10.2665 17.8093C10.3823 17.9339 10.4943 17.9494 11.9306 18.0739C12.5251 18.1245 12.5753 18.1245 12.6834 18.0623C12.8456 17.965 12.8688 17.7743 12.8456 16.4981C12.8263 15.2568 12.807 15.1712 12.5599 15.1012C12.4788 15.0778 12.363 15.0623 12.2973 15.0662C12.2356 15.07 12.0966 15.0778 11.9885 15.0856Z"
        fill="white"
      />
    </svg>
  );
}

export default ComicsIcon;
