import NoPicture from '@/components/ui/Picture/NoPicture';
import { Picture, PictureFormat, PictureRatio } from '@/types/model';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { MouseEventHandler, ReactNode, Ref } from 'react';
import classes from './DescriptionItem.module.css';

type Props = {
  title: string;
  href: string;
  description: string;
  subtitle?: string;
  pictureLabel?: ReactNode;
  pictureFormat?: PictureFormat;
  pictureRatio?: PictureRatio;
  picture?: Picture;
  lastItemRef?: Ref<any>;
  onClick?: MouseEventHandler;
};

function DescriptionItem(props: Props) {
  const { title, href, picture, subtitle, description, lastItemRef, onClick, pictureFormat } = props;

  let pictureNode;
  const format = pictureFormat ?? PictureFormat.Square;
  if (picture && !picture.sizes[format]) {
    console.log(picture, title);
  }
  if (picture && picture.sizes[format]) {
    pictureNode = (
      <Image
        src={generateUrl(picture, format)}
        alt={title}
        width={picture.sizes[format].width}
        height={picture.sizes[format].height}
      />
    );
  } else {
    pictureNode = <NoPicture firstLineText={'Aucune image'} secondLineText={'disponible'} />;
  }
  return (
    <Link href={href} onClick={onClick}>
      <span className={classes.container} ref={lastItemRef ? lastItemRef : null}>
        <span className={classes.picture}>{pictureNode}</span>
        <span className={classes.content}>
          <span className={classes.title}>{title}</span>
          {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
          <span className={classes.description}>{description}</span>
        </span>
      </span>
    </Link>
  );
}

export default DescriptionItem;
