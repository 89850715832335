import DescriptionItem from '@/components/ui/List/Item/DescriptionItem';
import VerticalList from '@/components/ui/List/VerticalList';
import SectionTitle from '@/components/ui/Title/SectionTitle';
import { PictureFormat, PictureRatio, SourceListItem } from '@/types/model';
import { trimSentence } from '@/utils/helpers';
import { getPathToSource } from '@/utils/routes';
import { MouseEventHandler, Ref } from 'react';
import classes from './VerticalEntryList.module.css';

type Props = {
  title?: string;
  items: Array<SourceListItem>;
  lastItemRef?: Ref<any>;
  onItemClick?: MouseEventHandler;
};

function VerticalSourceList(props: Props) {
  const { title, items, lastItemRef, onItemClick } = props;

  return (
    <section className={classes.container}>
      {title && <SectionTitle>{title}</SectionTitle>}
      <VerticalList>
        {items.map((item, i) => (
          <DescriptionItem
            key={i}
            title={item.name}
            href={getPathToSource(item)}
            subtitle={item.type.name}
            picture={item.image}
            pictureFormat={PictureFormat.Page}
            pictureRatio={PictureRatio.Ratio_2_3}
            description={trimSentence(item.description, 150)}
            lastItemRef={items.length === i + 1 ? lastItemRef : null}
            onClick={onItemClick}
          />
        ))}
      </VerticalList>
    </section>
  );
}

export default VerticalSourceList;
